import {useState} from "react";
import SignUpCreator from "../components/SignUp/SignUpCreator";
import SignUpStart from "../components/SignUp/SignUpStart";
import {withRouter} from "react-router-dom";

const SignUp = ({history}) => {
    const [isSignUpCreator, setIsSignUpCreator] = useState(false);

    const onSelectAccountType = (accountType) => {
        if (accountType === "brand") {
            history.push("/brand/signup");
        } else {
            setIsSignUpCreator(true);
        }
    }

    if (isSignUpCreator) {
        return <SignUpCreator/>;
    } else {
        return <SignUpStart onSelectAccountType={onSelectAccountType}/>;
    }
}

export default withRouter(SignUp);