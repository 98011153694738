import React, {useState} from 'react';

import {useIntl} from 'react-intl';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordInput = ({label = null, onChange, error, errorText, inputRef, onKeyPress, required}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const intl = useIntl();
    const defaultLabel = intl.formatMessage({
        id: 'Login.Password',
        defaultMessage: 'Enter your password'
    });
    const defaultErrorText = intl.formatMessage({
        id: 'Login.PasswordError',
        defaultMessage: 'Please enter a valid password'
    });
    const helperErrorText = (errorText ?? defaultErrorText);

    return (
        <TextField
            required={required}
            type={showPassword ? 'text' : 'password'}
            label={label ?? defaultLabel}
            variant="outlined"
            error={error}
            helperText={error ? helperErrorText : ''}
            onChange={onChange}
            onKeyPress={onKeyPress}
            inputRef={inputRef}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }} />
    )
};

export default PasswordInput;