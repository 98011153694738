import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AppLogo from '../components/AppLogo';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux";
import {loginSelector} from "../slices/login";
import {Checkbox, FormControlLabel, useTheme} from "@material-ui/core";
import PasswordInput from "../components/PasswordInput";
import PasswordValidator from "../components/PasswordValidator";
import Api from "../helpers/api";
import {setNotification} from "../slices/notification";
import qs from "qs";
import urlHelper from "../helpers/urlHelper";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import {isEmpty} from "../helpers/utils";
import PrimaryButton from "../components/CustomButton";
import routes from "../helpers/routes";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: theme.spacing(8, 0, 0, 0),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    logo: {
        maxHeight: "60px",
        maxWidth: "75%"
    },
    app_logo: {
        width: "75%"
    }
}));

const ROLE_CREATOR = 2;
const BASIC_INFO_STEP = 1;
const PASSWORD_STEP = 2;

const ClaimFlow = () => {
    const theme = useTheme()
    const {user} = useSelector(loginSelector);

    const [currentStep, setCurrentStep] = useState(BASIC_INFO_STEP);
    const classes = useStyles();
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [isTeamCreator, setIsTeamCreator] = useState(false);

    const dispatch = useDispatch();

    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const [showErrors, setShowErrors] = useState(false);

    const handlePasswordChange = (event) => {
        setIsPasswordMatch(event.target.value === confirmPassword);
        setPassword(event.target.value);
    };

    useEffect(() => {
        const userRole = user?.roles?.find(r => r.role.role_id === ROLE_CREATOR);
        if (userRole?.ambassador_type === 1 || userRole?.ambassador_type === 2) {
            setIsTeamCreator(true);
        }
    }, [user]);

    const handleConfirmPasswordChange = (event) => {
        setIsPasswordMatch(event.target.value === password);
        setConfirmPassword(event.target.value);
    };
    const getPasswordErrorMessage = () => {
        if (!isPasswordValid) {
            return "Password is not valid.";
        } else {
            return "";
        }
    }

    const executeUpdate = () => {
        const user_id = user.user_id;
        if (!user_id) {
            return;
        }

        setInProgress(true);
        Api.updateBasicData(user_id)(
            (response) => {
                setCurrentStep(PASSWORD_STEP);
                setInProgress(false);
            },
            (error) => {
                setInProgress(false);
                dispatch(setNotification("Operation failed. Cannot process the request. Contact support@creatable.io for assistance.", 'error'));
            }
        )({
            first_name: firstName.trim(),
            last_name: lastName.trim(),
        });
    }

    const deleteToken = () => {
        const {search} = window.location;
        const queryParams = qs.parse(search, {ignoreQueryPrefix: true});
        if (queryParams?.auth_token) {
            Api.deleteShortTokenAuth(user.user_id)(
                () => {
                    redirectToTargetDashboard();
                },
                () => {
                    redirectToTargetDashboard();
                }
            )({
                auth_token: queryParams?.auth_token
            })
        } else {
            redirectToTargetDashboard();
        }
    }

    const accountClaimed = () => {
        Api.updateUser(user.user_id)(
            () => {
                deleteToken();
            },
            () => {
                setInProgress(false);
                dispatch(setNotification("Operation failed. Cannot process the request. Contact support@creatable.io for assistance.", 'error'));
            }
        )({
            claimable: false
        });
    }

    const setAccountPassword = () => {
        setInProgress(true);
        Api.setPassword(user.user_id)(
            () => {
                accountClaimed();
            },
            () => {
                dispatch(setNotification("Operation failed. Cannot process the request. Contact support@creatable.io for assistance.", 'error'));
                setInProgress(false);
            }
        )({password: password});
    }

    const onClickContinue = () => {
        if (getIsContinueLooksDisabled()) {
            setShowErrors(true);
        } else if (currentStep === BASIC_INFO_STEP) {
            setShowErrors(false);
            executeUpdate();
        } else if (currentStep === PASSWORD_STEP) {
            setAccountPassword();
        }
    }

    const getIsContinueLooksDisabled = () => {
        if (currentStep === BASIC_INFO_STEP) {
            return (isEmpty(firstName) || isEmpty(lastName) || !isTermsChecked);
        } else if (currentStep === PASSWORD_STEP) {
            return (!isPasswordValid || !isPasswordMatch);
        }
    }

    const redirectTeamCreator = () => {
        const {search} = window.location;
        const queryParams = qs.parse(search, {ignoreQueryPrefix: true});
        if (queryParams?.continue && urlHelper.isValidUrl(queryParams?.continue)) {
            const url = new URL(queryParams?.continue);
            if (urlHelper.isAllowedHost(url.host)) {
                window.location.href = queryParams?.continue;
            } else {
                window.location.href = "/";
            }
        } else {
            window.location.href = "/";
        }
    }

    const redirectInfluencerCreator = () => {
        window.location.href = routes.getInfluencerDashboardSetupUrl();
    }

    const redirectToTargetDashboard = () => {
        if (isTeamCreator) {
            redirectTeamCreator();
        } else {
            redirectInfluencerCreator();
        }
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center"
              className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={2}>

                    <Box mt={2} mb={2} justifyContent="center" display="flex">
                        <AppLogo style={{width: "250px"}}/>
                    </Box>

                    <Typography variant="h5" align="center" gutterBottom>
                        {currentStep === BASIC_INFO_STEP ? "Access booking" : 'Set a password'}
                    </Typography>

                    {
                        (currentStep === BASIC_INFO_STEP) &&
                        <Typography variant="body2" align="center" gutterBottom>
                            Enter your details to access booking request
                        </Typography>
                    }

                    {
                        currentStep === BASIC_INFO_STEP &&
                        <Box mt={2} mb={0}>
                            <FormControl fullWidth margin={'dense'}>
                                <TextField variant="outlined"
                                           required
                                           label={'First name'}
                                           error={showErrors && isEmpty(firstName)}
                                           defaultValue={firstName}
                                           onChange={(e) => setFirstName(e.target.value)}
                                           helperText={(showErrors && isEmpty(firstName)) ? 'First name is required' : ''}
                                           autoFocus
                                />
                            </FormControl>
                            <FormControl fullWidth margin={'dense'}>
                                <TextField variant="outlined"
                                           required
                                           label={'Last name'}
                                           error={showErrors && isEmpty(lastName)}
                                           defaultValue={lastName}
                                           onChange={(e) => setLastName(e.target.value)}
                                           helperText={(showErrors && isEmpty(lastName)) ? 'Last name is required' : ''}
                                />
                            </FormControl>
                            <FormControl fullWidth margin={'dense'}>
                                <TextField variant="outlined"
                                           disabled
                                           defaultValue={user.email}
                                           label={'Email address'}
                                />
                            </FormControl>
                            <Box paddingTop={1}>
                                <Divider style={{marginBottom: 16, marginTop: 8}}/>
                                <FormControlLabel
                                    control={<Checkbox style={{
                                        paddingTop: 0,
                                        color: (showErrors && !isTermsChecked ? theme.palette.error.main : null)
                                    }} disableRipple
                                                       onChange={e => setIsTermsChecked(e.target.checked)}
                                                       defaultValue={false}/>}
                                    label={<Typography
                                        style={{lineHeight: '1.3'}}
                                        component={'p'} variant={'caption'}>I agree to the <Link
                                        target={'_blank'}
                                        href={'https://www.creatable.io/creator-terms'}>terms and
                                        conditions</Link> and <Link
                                        target={'_blank'}
                                        href={'https://creatable.io/privacy'}>privacy policy</Link></Typography>}/>
                            </Box>
                        </Box>
                    }

                    {
                        currentStep === PASSWORD_STEP &&
                        <Box mb={1}>
                            <FormControl fullWidth margin={"normal"}>
                                <PasswordInput error={(showErrors && !isPasswordValid)}
                                               errorText={getPasswordErrorMessage()}
                                               label={"Password"}
                                               onChange={handlePasswordChange}/>
                            </FormControl>
                            <FormControl fullWidth margin={"normal"}>
                                <PasswordInput error={showErrors && !isPasswordMatch}
                                               errorText={"Password and confirm password must match!"}
                                               label={"Confirm password"}
                                               onChange={handleConfirmPasswordChange}/>
                            </FormControl>
                            <PasswordValidator password={password} onValidate={setIsPasswordValid}/>
                        </Box>
                    }

                    <Box pt={2} pb={0}>
                        <PrimaryButton disabled={inProgress}
                                       onClick={onClickContinue}
                                       looksDisabled={getIsContinueLooksDisabled()}
                                       fullWidth>
                            CONTINUE
                        </PrimaryButton>
                    </Box>
                </Box>
                {inProgress && <LinearProgress/>}
            </Paper>
        </Grid>
    );

}

export default ClaimFlow;
