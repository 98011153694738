import React, {createContext} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";

const CustomThemeContext = createContext({name: 'CustomTheme'});
const {Consumer, Provider} = CustomThemeContext;

const withCustomTheme = (WrappedComponent) => (props) => {
    return (
        <Consumer>
            {
                theme => (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <WrappedComponent {...props}/>
                    </ThemeProvider>
                )
            }
        </Consumer>
    )
}

export {withCustomTheme};
export default Provider;