import React from "react";
import {useSelector} from "react-redux";
import {notificationSelector} from "../slices/notification";
import TopNotification from "./TopNotification";

const MainNotification = () => {
    const {isNotificationOpen, notificationMessage, notificationType} = useSelector(notificationSelector);
    return (
        <TopNotification open={isNotificationOpen} message={notificationMessage}
                         severity={notificationType}/>
    )
}

export default MainNotification;