import React, {useState} from "react";
import CenteredBox from "../CenteredBox";
import {Link as RouterLink, withRouter} from "react-router-dom";
import useOnboarding from "../../helpers/useOnboarding";
import BasicInfoStep from "./BasicInfoStep";
import SetPasswordStep from "./SetPasswordStep";
import routes from "../../helpers/routes";
import SignUpFooter from "./SignUpFooter";
import Box from "@material-ui/core/Box";
import DividerText from "../DividerText";
import AppleConnectButton from "../AppleConnectButton/AppleConnectButton";
import GoogleConnectButton from "../GoogleConnectButton/GoogleConnectButton";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {FormattedMessage} from "react-intl";
import useLocalStorage from "react-use-localstorage";

const BASIC_INFO_STEP = "basic_info_step";
const PASSWORD_STEP = "password_step";

const SignUpCreator = () => {
    const {inProgress, setInProgress} = useOnboarding();
    const [currentStep, setCurrentStep] = useState(BASIC_INFO_STEP);

    const [, setOauthProvider] = useLocalStorage('oauthProvider', null);

    const onBasicInfoSuccess = () => {
        setCurrentStep(PASSWORD_STEP);
    }

    const onSetPasswordSuccess = () => {
        window.location.href = routes.getInfluencerDashboardSetupUrl();
    }

    const handleClickOAuthConnect = (provider) => () => {
        setInProgress(true);
        setOauthProvider(provider);
        window.location.href = routes.getOAuthSignUpUrlByProvider(provider);
    }

    const getTitle = () => {
        if (currentStep === PASSWORD_STEP) {
            return "Set a password";
        } else {
            return "Sign up";
        }
    }

    return (
        <CenteredBox title={getTitle()} showLogo inProgress={inProgress} footer={<SignUpFooter/>}>
            {
                (BASIC_INFO_STEP === currentStep) &&
                <>
                    <BasicInfoStep onSuccess={onBasicInfoSuccess}/>
                    <Box mt={1} mb={1}>
                        <DividerText text={"or"}/>
                    </Box>

                    <Box mb={2}>
                        <AppleConnectButton title={"Continue with Apple"} onClick={handleClickOAuthConnect('apple')}/>
                    </Box>

                    <Box mb={1}>
                        <GoogleConnectButton title={"Continue with Google"} onClick={handleClickOAuthConnect('google')}/>
                    </Box>

                    <Box>
                        <DividerText text={"or"}/>
                    </Box>

                    <Box mt={1}>
                        <Typography variant="body2" align="center">
                            <Link component={RouterLink} to={routes.getForgotPasswordUrl()}>
                                <FormattedMessage id="Login.ForgotPassword" defaultMessage="Forgot password?"/>
                            </Link>
                        </Typography>
                    </Box>
                </>
            }
            {
                (PASSWORD_STEP === currentStep) &&
                <SetPasswordStep onSuccess={onSetPasswordSuccess}/>
            }
        </CenteredBox>
    )
}

export default withRouter(SignUpCreator);

