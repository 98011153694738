import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";

const AccountTypeButton = ({onClick, selected, type}) => {
    const classes = useStyles();
    return (
        <Paper elevation={0} variant="outlined" className={selected ? classes.checked : classes.unchecked}
               onClick={onClick}>
            <Box mb={1} mt={1}>
                <Box textAlign={"center"}>
                    {
                        type === "creator" &&
                        <RecordVoiceOverOutlinedIcon htmlColor={selected ? "#fff" : "#000"}
                                                     style={{width: 48, height: 48}}/>
                    }
                    {
                        type === "brand" &&
                        <StorefrontOutlinedIcon htmlColor={selected ? "#fff" : "#000"}
                                                style={{width: 48, height: 48}}/>
                    }
                </Box>
                <Typography variant={"body2"} align={"center"} style={{color: (selected ? "#fff" : "#000")}}>
                    {type === "creator" && "I'm a creator"}
                    {type === "brand" && "I'm a brand"}
                </Typography>
            </Box>
        </Paper>
    )
}

const useStyles = makeStyles({
    checked: {
        backgroundColor: '#000',
        borderRadius: 12,
        cursor: "pointer",
    },
    unchecked: {
        backgroundColor: '#fff',
        borderRadius: 12,
        cursor: "pointer",
    },
});

export default AccountTypeButton;