import Box from "@material-ui/core/Box";
import LoadingApp from "../components/LoadingApp";
import {useParams, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getMe, loginSelector, setLoginFromCampaign} from "../slices/login";
import {useEffect} from "react";
import api from "../helpers/api";

const BookNow = ({history}) => {
    const {isAuthenticated, user} = useSelector(loginSelector);
    const dispatch = useDispatch();
    const {reference} = useParams();
    useEffect(() => {
        if(isAuthenticated === null){
            dispatch(getMe());
        }else if(isAuthenticated === true && reference){
            api.campaigns.login()(
                response=>{
                    dispatch(setLoginFromCampaign(response.data));
                    history.push("/?bookReference=" + reference);
                },
                error =>{
                    history.push("/?bookReference=" + reference);
                }
            )({cookie: document.cookie, userId: user.user_id})

        }else if (isAuthenticated === false && reference){
            history.push("/brand/signup?bookReference="+reference);
        }
    }, [dispatch, reference, isAuthenticated, history, user]);
    return <Box>
        <LoadingApp />
    </Box>
}
export default withRouter(BookNow);