import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import routes from "../../helpers/routes";
import React from "react";

const LoginFooter = () => {
    return (
        <Box>
            <Box mt={4}>
                <Typography align="center" gutterBottom component={'p'} variant={'body2'}>
                    Don't have an account?
                </Typography>
            </Box>

            <Box mt={1}>
                <Typography variant="body2" align="center" style={{fontWeight: 500}}>
                    <Link component={RouterLink} to={routes.getSignUpUrl()}>
                        Sign up →
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
}

export default LoginFooter;