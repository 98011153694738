import Box from "@material-ui/core/Box";
import React, {useEffect, useState} from "react";
import qs from "qs";
import api from "../helpers/api";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {AppBar, Container, Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {makeStyles} from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import routes from "../helpers/routes";

const useStyles = makeStyles( theme=> ({
    internalCaptcha: {
        "& > div":{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            justifyContent: 'center'
        }
    }
}));

const BrandOnboardingValidation = () =>{
    const [initialized, setInitialized] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [captcha, setCaptcha] = useState(false);
    const [step, setStep] = useState(0);
    const [inProgress, setInProgress] = useState(false);
    const classes = useStyles();

    const captchaSolver = (token, ekey)=>{
        setCaptcha(token);
        setDisabled(false);
    };

    const onClickContinue = () =>{
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        if(params?.verification_code){
            setInProgress(true);
            api.client.emailVerification(params.verification_code, captcha, null)(
                response =>{
                    const {short_lived_token, campaign_id, client_hash} = response.data;
                    setInProgress(false);
                    const continueUrl = routes.getBrandAppUrl(client_hash, `/influencer/campaigns/booking/${campaign_id}/wizard`);
                    if(campaign_id){
                        window.location.href = "/?authToken="+short_lived_token+"&continue="+continueUrl;
                    }else{
                        window.location.href = "/?authToken="+short_lived_token;
                    }
                },
                error =>{
                    setInProgress(false);
                    setStep(1)
                }
            )()
        }else{
            setStep(1);
        }
    }

    useEffect(() => {
        if(!initialized){
            const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
            setInitialized(true);
            const secretKey = window?.creatableKey || null;
            if(secretKey && params?.verification_code){
                setInProgress(true);
                api.client.emailVerification(params.verification_code, null, secretKey)(
                    response =>{
                        const {short_lived_token, campaign_id, client_hash} = response.data;
                        setInProgress(false);
                        const continueUrl = routes.getBrandAppUrl(client_hash, `/influencer/campaigns/booking/${campaign_id}/wizard`);
                        if(campaign_id){
                            window.location.href = "/?authToken="+short_lived_token+"&continue="+continueUrl;
                        }else{
                            window.location.href = "/?authToken="+short_lived_token;
                        }

                    },
                    error =>{
                        setInProgress(false);
                        setStep(1)
                    }
                )()
            }

        }
    }, [initialized]);

    return <Box>
        <Box bgcolor={'#000000'}>
            <AppBar color={'transparent'} position={'relative'}>
                <Toolbar>
                    <img height={'34px'} src={"https://sitecdn.tvpage.com/static/images/creatable_logo_white.svg"} alt={'Creatable'}/>
                </Toolbar>
            </AppBar>
        </Box>
        {inProgress && <LinearProgress />}
        {step === 0 && <Box pt={2}>
            <Container maxWidth={'xs'}>
                <Box display={'flex'} justifyContent={'center'} pb={1}>
                    <BusinessIcon style={{width: '64px', height: '64px'}} />
                </Box>
                <Typography variant={'h5'} align={'center'}>Account activated</Typography>
                <Box maxWidth={'350px'} ml={'auto'} mr={'auto'} pt={1}>
                    <Typography align={'center'}>
                        Your account is now active.
                    </Typography>
                    <Typography align={'center'}>
                        Click below to continue.
                    </Typography>
                </Box>
                <Box className={classes.internalCaptcha} pt={1.5}>
                    <HCaptcha sitekey={'cd5a093b-8427-45b1-b6c8-dc55de41329b'} onVerify={(token, ekey)=>captchaSolver(token, ekey)} />
                </Box>
                <Box pt={2} pb={2}>
                    <Button onClick={onClickContinue} disabled={disabled} color={'primary'} disableRipple  fullWidth variant={'contained'}>
                        Continue
                    </Button>
                </Box>
                <Box>
                    <Typography align={'center'}>Still having trouble?</Typography>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Link href={'mailto:support@creatable.io'}>Contact us</Link>
                </Box>
            </Container>

        </Box>}
        { step === 1 && <Box pt={2}>

            <Container maxWidth={'xs'}>
                <Box display={'flex'} justifyContent={'center'} pb={1}>
                    <ErrorOutlineIcon style={{width: '64px', height: '64px'}} />
                </Box>
                <Typography variant={'h5'} align={'center'}>Oops!</Typography>
                <Box maxWidth={'350px'} ml={'auto'} mr={'auto'} pt={1} pb={1}>
                    <Typography align={'center'}>
                        Something went wrong!
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <Link href={'mailto:support@creatable.io'}>Contact us</Link>
                </Box>
            </Container>
        </Box>}
    </Box>
}
export default BrandOnboardingValidation;