import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        position: "relative",
        paddingTop: 15,
        paddingBottom: 15,
    },
    textContainer: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        backgroundColor: "#fff",
        paddingLeft: 10,
        paddingRight: 10,
    }
});

const DividerText = ({text}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Divider/>
            <Box className={classes.textContainer}>
                <Typography variant="body2" className={classes.text}>
                    {text}
                </Typography>
            </Box>
        </Box>
    );
}

export default DividerText;