import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from 'react-router';
import {useSelector} from 'react-redux';
import {loginSelector} from '../slices/login';
import routes from '../helpers/routes';
import api from '../helpers/api';
import RequestError from '../helpers/RequestError';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import {FormattedMessage, useIntl} from 'react-intl';
import TopNotification from '../components/TopNotification';

import * as EmailValidator from 'email-validator';

import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: theme.spacing(8, 0, 0, 0),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
}));

const ForgotPassword = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const emailLabel = intl.formatMessage({
        id: 'Login.Email',
        defaultMessage: 'enter_email'
    });
    const {email} = useSelector(loginSelector);
    const [currentStatus, setCurrentStatus] = useState('enter_email');
    const [requestError, setRequestError] = useState({isError: false, message: ''});
    const [typedEmail, setTypedEmail] = useState(email);
    const emailRef = useRef();

    useEffect(() => {
        emailRef && emailRef.current.focus();
    }, []);

    const handleEmailChange = (event) => {
        setTypedEmail(event.target.value);
        setCurrentStatus('enter_email');
    };
    const handleClickResetPassword = (event) => {
        if (EmailValidator.validate(typedEmail) && typedEmail.trim() !== '') {
            setCurrentStatus('submitting');
            api.sendResetPasswordEmail()(
                response => setCurrentStatus('confirmation'),
                error => {
                    const rError = new RequestError(error);
                    setRequestError({
                        isError: true,
                        message: rError.getMessage()
                    });
                    setCurrentStatus('enter_email');
                }
            )({email: typedEmail});
        } else {
            setCurrentStatus('invalid_email');
        }
    };
    const handleClickBackToLogin = (event) => {
        props.history.push(routes.getLoginUrl());
    };

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={3}>
                

                    {
                        (currentStatus !== 'confirmation') &&
                        <Box mb={2}>

                            <Box mb={3}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="Login.RecoverPassword"
                                        defaultMessage="Recover your password" />
                                </Typography>
                            </Box>

                            <Box mb={4}>
                                <Typography variant="body1" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="Login.RecoverPasswordMessage"
                                        defaultMessage="Enter your email address below and we'll send you a link to reset your password." />
                                </Typography>
                            </Box>

                            <FormControl fullWidth>
                                <TextField label={emailLabel} variant="outlined" onChange={handleEmailChange}
                                    inputRef={emailRef}
                                    value={typedEmail}
                                    error={currentStatus === 'invalid_email'}
                                    helperText={currentStatus === 'invalid_email' ? 'Invalid email' : ''}
                                    required/>
                            </FormControl>

                            <Box mt={2}>
                                <Button variant="contained" color="primary" onClick={handleClickResetPassword} fullWidth>
                                    <FormattedMessage
                                        id="Login.ResetPassword"
                                        defaultMessage="Reset password" />
                                </Button>
                            </Box>

                            <Box mt={4}>
                                <Button color="primary" onClick={handleClickBackToLogin} fullWidth startIcon={<ArrowBackIcon/>}>
                                    <FormattedMessage
                                        id="Login.BackToSignIn"
                                        defaultMessage="Back to sign in" />
                                </Button>
                            </Box>
                        </Box>
                    }

                    {
                        (currentStatus === 'confirmation') &&
                        <Box mb={2}>

                            <Box mb={3}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="Login.CheckYourEmail"
                                        defaultMessage="Check your email" />
                                </Typography>
                            </Box>

                            <Box mt={2}>
                                <Typography variant="body1" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="Login.RecoverPasswordConfirmationSupport"
                                        defaultMessage="If you don't receive an email in the coming minutes, contact {email} for assistance."
                                        values={{
                                            email: (
                                                <Link href="mailto:support@creatable.io" color="primary">
                                                    support@creatable.io
                                                </Link>
                                            )
                                        }} />
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                <Button color="primary" onClick={handleClickBackToLogin} fullWidth startIcon={<ArrowBackIcon/>}>
                                    <FormattedMessage
                                        id="Login.SignIn"
                                        defaultMessage="Sign In" />
                                </Button>
                            </Box>
                        </Box>
                    }
                    
                </Box>
                {currentStatus === 'submitting' && <LinearProgress />}
                <Backdrop className={classes.backdrop} open={currentStatus === 'submitting'}/>
                <TopNotification open={requestError.isError} severity="error" message={requestError.message}/>
            </Paper>
        </Grid>
    );
}

export default withRouter(ForgotPassword);