import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router';
import routes from '../helpers/routes';
import api from '../helpers/api';
import RequestError from '../helpers/RequestError';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {FormattedMessage, useIntl} from 'react-intl';

import PasswordInput from '../components/PasswordInput';
import PasswordValidator from '../components/PasswordValidator';
import TopNotification from '../components/TopNotification';

import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: theme.spacing(8, 0, 0, 0),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
}));

const ResetPassword = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const {match: {params: {token}}} = props;
    const newPasswordLabel = intl.formatMessage({
        id: 'ResetPassword.NewPassword',
        defaultMessage: 'Enter new password'
    });
    const confirmNewPasswordLabel = intl.formatMessage({
        id: 'ResetPassword.ConfirmNewPassword',
        defaultMessage: 'Confirm new password'
    });
    const verifyErrorsText = intl.formatMessage({
        id: 'ResetPassword.VerifyErrors',
        defaultMessage: 'Please fix the erros in form'
    });
    const [currentStatus, setCurrentStatus] = useState('loading');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [errorNotification, setErrorNotification] = useState({isError: false, message: ''});
    const [fieldsData, setFieldsData] = useState({password: '', passwordConfirm: ''});

    const handleChange = (fieldName) => (event) => {
        setFieldsData({...fieldsData, [fieldName]: event.target.value});
        setErrorNotification({isError: false, message: ''});
    };
    const handleClickResetPassword = () => {
        if (isPasswordValid) {
            setCurrentStatus('loading');
            api.resetPasswordWithToken()(
                response => setCurrentStatus('confirmation'),
                error => {
                    const requestError = new RequestError(error);
                    setErrorNotification({isError: true, message: requestError.getMessage()});
                    setCurrentStatus('change_password');
                }
            )({password: fieldsData.password, password_confirm: fieldsData.passwordConfirm, token});
        } else {
            setErrorNotification({isError: true, message: verifyErrorsText});
        }
    };

    const handleClickBackToLogin = (event) => {
        props.history.push(routes.getLoginUrl());
    };

    const handleClickBackToResendEmail = (event) => {
        props.history.push(routes.getForgotPasswordUrl());
    };

    useEffect(() => {
        setCurrentStatus('loading');
        if (token.trim() !== '') {
            api.validateResetPasswordToken()(
                response => setCurrentStatus('change_password'),
                error => {
                    if (error.response.status === 404) {
                        setCurrentStatus('invalid_token');
                    } else {
                        const requestError = new RequestError(error);
                        setErrorNotification({
                            isError: true,
                            message: requestError.getMessage()
                        });
                        setCurrentStatus('change_password');
                    }
                }
            )({token});
        } else {
            setCurrentStatus('invalid_token');
        }
    }, [setCurrentStatus, token]);

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={3}>
                
                    {
                        (currentStatus === 'change_password' || currentStatus === 'loading') &&
                        <Box>

                            <Box mt={1}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="ResetPassword.ResetPassword"
                                        defaultMessage="Reset password" />
                                </Typography>
                            </Box>

                            <Box pt={2} mt={4}>
                                <FormControl fullWidth>
                                    <PasswordInput  label={newPasswordLabel}
                                                    onChange={handleChange('password')}
                                                    error={errorNotification.isError && fieldsData.password.trim() === ''} />
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <FormControl fullWidth>
                                    <PasswordInput  label={confirmNewPasswordLabel}
                                                    onChange={handleChange('passwordConfirm')}
                                                    error={errorNotification.isError && fieldsData.passwordConfirm.trim() === ''} />
                                </FormControl>
                            </Box>

                            <Box mt={2}>
                                <PasswordValidator password={fieldsData.password} onValidate={setIsPasswordValid} />
                            </Box>

                            <Box mt={2}>
                                <Button variant="contained" color="primary" onClick={handleClickResetPassword} fullWidth>
                                    <FormattedMessage
                                        id="ResetPassword.ResetPassword"
                                        defaultMessage="Reset password" />
                                </Button>
                            </Box>
                        </Box>
                    }

                    {
                        (currentStatus === 'confirmation') &&
                        <Box mb={2}>

                            <Box mb={3}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="ResetPassword.ResetPasswordConfirmationTitle"
                                        defaultMessage="Success" />
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant="body1" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="ResetPassword.ResetPasswordConfirmationMessage"
                                        defaultMessage="Password successfully changed"/>
                                </Typography>
                            </Box>
                        </Box>
                    }

                    {
                        (currentStatus === 'invalid_token') &&
                        <Box mb={2}>

                            <Box mb={3}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    <FormattedMessage
                                        id="ResetPassword.ExpiredLink"
                                        defaultMessage="Reset password link expired" />
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                <Button variant="contained" color="primary" onClick={handleClickBackToResendEmail} fullWidth>
                                    <FormattedMessage
                                        id="ResetPassword.ResetAgainButton"
                                        defaultMessage="Reset password again" />
                                </Button>
                            </Box>
                        </Box>
                    }

                    <Box mt={3}>
                        <Button color="primary" onClick={handleClickBackToLogin} fullWidth startIcon={<ArrowBackIcon/>}>
                            <FormattedMessage
                                id="Login.BackToSignIn"
                                defaultMessage="Back to sign in" />
                        </Button>
                    </Box>
                    
                </Box>
                {currentStatus === 'loading' && <LinearProgress />}
                <Backdrop className={classes.backdrop} open={currentStatus === 'loading'}/>
                <TopNotification open={errorNotification.isError} severity="error" message={errorNotification.message}/>
            </Paper>
        </Grid>
    );
}

export default withRouter(ResetPassword);
