import React, {useState, useEffect, Fragment} from 'react';
import Api from '../helpers/api';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const POOLING_INTERVAL = 30000;

const MaintenanceModeMessage = ({open, title, message}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

const ReloadPageMessage = ({open}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>A new version is available!</DialogTitle>
            <DialogContent>
                <DialogContentText>Please, reload the page to see the updates.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => window.location.reload()}>
                    Click here to reload
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const withApplicationStatusDetector = (WrappedComponent) => {
    const WrappedWithApplicationStatusDetector = (props) => {
        const [maintenance, setMaintenance] = useState({active: false, title: '', message: ''});
        const [isFocused, setIsFocused] = useState(true);
        const [applicationVersion, setApplicationVersion] = useState(null);
        const [reloadPage, setReloadPage] = useState(false);
        const {isNetworkDisconnected = false} = props;

        useEffect(() => {

            const onWindowFocus = () => setIsFocused(true);
            const onWindowBlur = () => setIsFocused(false);
            window.addEventListener('focus', onWindowFocus);
            window.addEventListener('blur', onWindowBlur);

            return () => {
                window.removeEventListener('focus', onWindowFocus);
                window.removeEventListener('blur', onWindowBlur);
            };
        }, []);

        useEffect(() => {

            let activePooling = 0;

            const verifyApplicationStatus = () => {
                Api.getApplicationStatus()(
                    (response) => {
                        const {maintenance_mode, maintenance_title, maintenance_message} = response.data;
                        if (maintenance_mode) {
                            setMaintenance({
                                active: maintenance_mode,
                                title: maintenance_title,
                                message: maintenance_message,
                            });
                        } else {
                            setMaintenance({active: false});
                        }
                    },
                    (error) => {
                        setMaintenance({active: false});
                        console.error(error);
                    }
                );
            }

            const startPooling = () => {
                verifyApplicationStatus();
                verifyApplicationVersion();
                activePooling = setInterval(() => {
                    verifyApplicationStatus();
                    verifyApplicationVersion();
                }, POOLING_INTERVAL);
            }

            const verifyApplicationVersion = () => {
                Api.getApplicationRequestHeaders()(
                    (response) => {
                        const lastModified = response.headers.hasOwnProperty('last-modified') ? response.headers['last-modified'] : '';
                        if (applicationVersion !== null && lastModified !== applicationVersion) {
                            setReloadPage(true);
                        }
                        setApplicationVersion(lastModified);
                    },
                    (error) => console.log(error)
                );
            }

            if (isFocused && isNetworkDisconnected === false && reloadPage === false) {
                startPooling();
            } else {
                clearInterval(activePooling);
            }

            return () => {
                clearInterval(activePooling);
            }
        }, [isFocused, isNetworkDisconnected, applicationVersion, reloadPage]);

        return (
            <Fragment>
                <MaintenanceModeMessage open={maintenance.active} title={maintenance.title} message={maintenance.message}/>
                <ReloadPageMessage open={reloadPage}/>
                <WrappedComponent {...props} />
            </Fragment>
        );
    }

    return WrappedWithApplicationStatusDetector;
}

export default withApplicationStatusDetector;