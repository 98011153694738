import environment from "./environment";

const cookieHelper = {
    getAuthCookieName: () => {
        if (environment.isProduction()) {
            return "tvp_user_hash";
        } else {
            return `${environment.getName()}_tvp_user_hash`;
        }
    },

    getImpersonationCookieName: () => {
        if (environment.isProduction()) {
            return "tvp_impersonation_user_hashes";
        } else {
            return `${environment.getName()}_tvp_impersonation_user_hashes`;
        }
    },
}

export default cookieHelper;