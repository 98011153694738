export const object = {
    get: (sp, o, d = null) => sp.split('.').reduce((r, a) => (r && r[a]) ? r[a] : d, o),  
};

export const strings = {
    capitalize: (str) => ((typeof str === "string") ? str.charAt(0).toUpperCase() + str.slice(1) : ""),
};

export const isURL = url => {
    try{
        return new URL(url);
    }
    catch(e){
        return false;
    }
}

export const app = {
    clearStorage: () => {
        if (typeof sessionStorage != 'undefined') {
            sessionStorage.clear();
        }
        if (typeof localStorage != 'undefined') {
            localStorage.clear();
        }
    }
};

export const isEmpty = v => (v === undefined || v === null || v === "" || v === 0 || parseFloat(v) === 0 || (Array.isArray(v) && v.length === 0));
export const asBoolean = v => (v === 1 || v === "1" || v === true || v === "true" || !isEmpty(v));