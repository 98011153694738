import {createSlice} from '@reduxjs/toolkit';

const NOTIFICATION_TIMEOUT = 2500;

const initialState = {
    isNotificationOpen: false,
    notificationMessage: "",
    notificationType: "",
    notificationTimeoutHandler: null,
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        openNotification: (state, {payload}) => {
            state.isNotificationOpen = true;
            state.notificationMessage = payload.message;
            state.notificationType = payload.type;
        },
        closeNotification: (state) => {
            state.isNotificationOpen = false;
        },
        setTimeoutHandler: (state, {payload}) => {
            state.notificationTimeoutHandler = payload;
        },
        clearTimeoutHandler: (state) => {
            clearTimeout(state.notificationTimeoutHandler);
            state.notificationTimeoutHandler = null;
        },
    },
})
export const {openNotification, closeNotification, setTimeoutHandler, clearTimeoutHandler} = notificationSlice.actions;
export const notificationSelector = state => state.notification;
export default notificationSlice.reducer

export const setNotification = (message, type = "success") => dispatch => {
    dispatch(clearTimeoutHandler());
    dispatch(openNotification({message, type}));
    dispatch(setTimeoutHandler(setTimeout(() => {
        dispatch(closeNotification());
        dispatch(clearTimeoutHandler());
    }, NOTIFICATION_TIMEOUT)))
}