
const getValueFromClassByHostname = (fromClass) => {
    switch (window.location.host){
        case ENV_LOGIN_APP_HOSTS.LOCAL:
            return fromClass.LOCAL;
        case ENV_LOGIN_APP_HOSTS.TEST:
            return fromClass.TEST;
        case ENV_LOGIN_APP_HOSTS.STAGE:
            return fromClass.STAGE;
        case ENV_LOGIN_APP_HOSTS.PROD:
            return fromClass.PROD;
        default:
            throw new Error(`Couldn't determine environment`);
    }
}

const environment = {
    getName: () => getValueFromClassByHostname(ENV_NAMES),
    getUserServiceHost: () => getValueFromClassByHostname(ENV_USER_SERVICE_HOSTS),
    getClientServiceHost: () => getValueFromClassByHostname(ENV_CLIENT_SERVICE_HOSTS),
    getMerchantAppHost: () => getValueFromClassByHostname(ENV_MERCHANT_APP_HOSTS),
    getCreatorAppHost: () => getValueFromClassByHostname(ENV_CREATOR_APP_HOSTS),
    getCreatorsAppHost: () => getValueFromClassByHostname(ENV_CREATORS_APP_HOSTS),
    getLegacyCreatorAppHost: () => getValueFromClassByHostname(ENV_MERCHANT_APP_HOSTS),
    getBrandAppHost: () => getValueFromClassByHostname(ENV_BRAND_APP_HOSTS),
    isProduction: () => environment.getName() === ENV_NAMES.PROD,
    getAdminAppHost: () => getValueFromClassByHostname(ENV_ADMIN_APP_HOSTS),
}

const ALLOWED_BASE_DOMAINS = ["creatable.io", "tvpage.com"];
const getBaseDomain = () => ALLOWED_BASE_DOMAINS.find(d => window.location.host.includes(d)) || ALLOWED_BASE_DOMAINS[0];

class ENV_NAMES {
    static get LOCAL()  { return 'local' };
    static get TEST()   { return 'test' };
    static get STAGE()  { return 'stage' };
    static get PROD()   { return 'prod' };
}

class ENV_USER_SERVICE_HOSTS {
    static get LOCAL()  { return `local.${getBaseDomain()}` };
    static get TEST()   { return `test.${getBaseDomain()}` };
    static get STAGE()  { return `stage.${getBaseDomain()}` };
    static get PROD()   { return `app.${getBaseDomain()}` };
}

class ENV_CLIENT_SERVICE_HOSTS {
    static get LOCAL()  { return `test.${getBaseDomain()}` };
    static get TEST()   { return `test.${getBaseDomain()}` };
    static get STAGE()  { return `stage.${getBaseDomain()}` };
    static get PROD()   { return `app.${getBaseDomain()}` };
}

class ENV_LOGIN_APP_HOSTS {
    static get LOCAL()  { return `login.local.${getBaseDomain()}` };
    static get TEST()   { return `login.test.${getBaseDomain()}` };
    static get STAGE()  { return `login.stage.${getBaseDomain()}` };
    static get PROD()   { return `login.${getBaseDomain()}` };
}

class ENV_MERCHANT_APP_HOSTS {
    static get LOCAL()  { return `local.${getBaseDomain()}` };
    static get TEST()   { return `test.${getBaseDomain()}` };
    static get STAGE()  { return `stage.${getBaseDomain()}` };
    static get PROD()   { return `app.${getBaseDomain()}` };
}

class ENV_CREATOR_APP_HOSTS {
    static get LOCAL()  { return `creator.local.${getBaseDomain()}` };
    static get TEST()   { return `creator.test.${getBaseDomain()}` };
    static get STAGE()  { return `creator.stage.${getBaseDomain()}` };
    static get PROD()   { return `creator.${getBaseDomain()}` };
}

class ENV_CREATORS_APP_HOSTS {
    static get LOCAL()  { return `creators.local.${getBaseDomain()}` };
    static get TEST()   { return `creators.test.${getBaseDomain()}` };
    static get STAGE()  { return `creators.stage.${getBaseDomain()}` };
    static get PROD()   { return `creators.${getBaseDomain()}` };
}

class ENV_BRAND_APP_HOSTS {
    static get LOCAL()  { return `brand.local.${getBaseDomain()}` };
    static get TEST()   { return `brand.test.${getBaseDomain()}` };
    static get STAGE()  { return `brand.stage.${getBaseDomain()}` };
    static get PROD()   { return `brand.${getBaseDomain()}` };
}

class ENV_ADMIN_APP_HOSTS {
    static get LOCAL()  { return `admin.local.${getBaseDomain()}` };
    static get TEST()   { return `admin.test.${getBaseDomain()}` };
    static get STAGE()  { return `admin.stage.${getBaseDomain()}` };
    static get PROD()   { return `admin.${getBaseDomain()}` };
}

export {ENV_NAMES};
export default environment;