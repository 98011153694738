import React from "react";

const LOGO_URL = 'https://sitecdn.tvpage.com/static/images/creatable_logo.svg';

const AppLogo = (props) => {
    return (
        <img alt="Creatable" {...props} src={LOGO_URL} />
    )
}

export default AppLogo;