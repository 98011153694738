import {useDispatch} from "react-redux";
import {setNotification} from "../slices/notification";
import sentry from "./sentry";
import RequestError from "./RequestError";
import {useCallback} from "react";

const useNotification = () => {
    const dispatch = useDispatch();

    const error = useCallback((error) => {
        if (typeof error === "object") {
            const reqError = new RequestError(error);
            dispatch(setNotification(reqError.getMessage(), "error"));
        } else {
            dispatch(setNotification(error, "error"));
        }
    }, [dispatch]);

    const info = useCallback((error) => {
        dispatch(setNotification(error, "info"));
    }, [dispatch]);

    const success = useCallback((message) => {
        dispatch(setNotification(message, "success"));
    }, [dispatch]);

    const warning = useCallback((message) => {
        dispatch(setNotification(message, "warning"));
    }, [dispatch]);

    const captureError = useCallback((err, defaultMessage) => {
        error(defaultMessage ?? "There was a problem. Please try again or contact support@creatable.io.");
        sentry.captureException(err);
        console.error(err);
    }, [error]);

    return {error, info, success, warning, captureError};
};

export default useNotification;
