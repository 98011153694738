import {object as objectUtils} from './utils';

class RequestError {
    
    constructor(errorResponse) {
        this.errorResponse = errorResponse;
    }

    isHydraResponse() {
        return objectUtils.get('@type', this.errorResponse) === 'hydra:Error';
    }

    getMessage() {
        if (this.isHydraResponse()) {
            const title = objectUtils.get('response.data.hydra:title', this.errorResponse);
            const description = objectUtils.get('response.data.hydra:description', this.errorResponse);
            return `${title}: ${description}`;
        } else if (objectUtils.get('response.data.message', this.errorResponse, false)) {
            return objectUtils.get('response.data.message', this.errorResponse);
        } else {
            return objectUtils.get('message', this.errorResponse, 'Unknown error');
        }
    }

    getHttpStatusCode() {
        return objectUtils.get('response.status', this.errorResponse);
    } 
}

export default RequestError;