import React, {useCallback, useEffect} from "react";
import LoadingApp from "../components/LoadingApp";
import Api from "../helpers/api";
import {useCookies} from "react-cookie";
import cookieHelper from "../helpers/cookieHelper";
import routes from "../helpers/routes";
import {withRouter} from "react-router-dom";

const isSecure = () => window.location.protocol === "https";

const Logout = ({history}) => {
    const [,, removeCookie] = useCookies([cookieHelper.getAuthCookieName(), cookieHelper.getImpersonationCookieName()]);

    const removeCookies = useCallback(() => {
        removeCookie(cookieHelper.getAuthCookieName(), {secure: isSecure()});
        removeCookie(cookieHelper.getImpersonationCookieName(), {secure: isSecure()});
    }, [removeCookie]);

    useEffect(() => {
        Api.logout()(
            () => {}, () => {}
        ).then(() => removeCookies()).then(() => window.location.href = routes.getLoginAppUrl());
    }, [removeCookies, history]);

    return (
        <LoadingApp/>
    )
}

export default withRouter(Logout);