import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {loginSelector} from '../slices/login';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {FormattedMessage} from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import {makeStyles} from '@material-ui/core/styles';
import roleHelper, {DASHBOARDS} from "../helpers/roleHelper";
import {AccountCircle, Help, StarBorder, SupervisorAccount} from "@material-ui/icons";
import MarketplaceIcon from "../components/MarketplaceIcon";
import {isEmpty} from "../helpers/utils";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    list: {
        height: 'calc(100vh - 120px)',
        maxHeight: '300px',
        overflowY: 'auto',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    iconContainer: {
        "& .MuiSvgIcon-root": {
            color: "#fff",
        },
    },
}));

const DashboardSelector = ({onClickDashboard, onClickLogout}) => {
    const classes = useStyles();
    const {user, isAuthenticated} = useSelector(loginSelector);

    const [showSpinner, setShowSpinner] = useState(user === null);
    const [dashboards, setDashboards] = useState([]);
    const [inactiveAccountMessage, setInactiveAccountMessage] = useState("");

    useEffect(() => {
        if (isAuthenticated) {
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (user) {
            const roles = roleHelper.getActiveRolesFromUser(user);
            const dashboardsForRoles = [...new Set(roles.map(role => roleHelper.getDashboardsFromRoleId(role.role_id)).reduce((a, d) => ([
                ...a, ...d
            ]), []))];
            setDashboards(dashboardsForRoles);
            const lastInactiveRole = roleHelper.getLastInactiveRole(user);
            if (lastInactiveRole && dashboardsForRoles.length === 0) {
                setInactiveAccountMessage(roleHelper.getInactiveAccountMessage(user)?.message || "");
            }
        }
    }, [user]);

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={2}>

                    {
                        Array.isArray(dashboards) && dashboards.length === 0 && isEmpty(inactiveAccountMessage) &&
                        <Box mt={0}>
                            <Typography variant="h5" align="center">
                                <FormattedMessage id="Login.ChooseDashboard" defaultMessage="Choose your dashboard"/>
                            </Typography>
                        </Box>
                    }

                    <Box mt={3} className={classes.list}>
                        {
                            Array.isArray(dashboards) && dashboards.length > 0 &&
                            <List>
                                {dashboards.map(dashboard => (
                                    <ItemDashboard key={dashboard}
                                                   dashboard={dashboard}
                                                   onClick={() => onClickDashboard(dashboard)}/>
                                ))}
                            </List>
                        }
                        {
                            Array.isArray(dashboards) && dashboards.length === 0 && isEmpty(inactiveAccountMessage) &&
                            <Box display={"flex"} alignItems={"center"} mx={2} height={"100%"}>
                                <Typography variant={"body1"} align={"center"}>
                                    You do not have access to any dashboards.
                                </Typography>
                            </Box>
                        }
                        {
                            Array.isArray(dashboards) && dashboards.length === 0 && !isEmpty(inactiveAccountMessage) &&
                            <Box display={"flex"} alignItems={"center"} mx={2} height={"100%"}>
                                <Typography variant={"body1"} align={"center"}>
                                    {inactiveAccountMessage}
                                </Typography>
                            </Box>
                        }
                    </Box>

                    <Box mt={4}>
                        <Typography variant="body2" align="center">
                            <Link color="primary" onClick={onClickLogout} href={"#"}>
                                <FormattedMessage id="Login.Logout" defaultMessage="Logout"/>
                            </Link>
                        </Typography>
                    </Box>

                </Box>
                {showSpinner && <LinearProgress/>}
                <Backdrop className={classes.backdrop} open={showSpinner}/>
            </Paper>
        </Grid>
    );
}

const DashboardIcon = ({dashboard, alt}) => {
    const classes = useStyles();

    const getColor = () => {
        if ([DASHBOARDS.DASHBOARD_CREATOR, DASHBOARDS.DASHBOARD_MARKETPLACE].includes(dashboard)) {
            return "#dc2185";
        } else if (dashboard === DASHBOARDS.DASHBOARD_BRAND) {
            return "#0283ee";
        } else if (dashboard === DASHBOARDS.DASHBOARD_ADMIN) {
            return "#44ca9a";
        } else {
            return "#c0c0c0";
        }
    }

    const getIcon = () => {
        if (dashboard === DASHBOARDS.DASHBOARD_CREATOR) {
            return <AccountCircle/>;
        } else if (dashboard === DASHBOARDS.DASHBOARD_BRAND) {
            return <StarBorder/>;
        } else if (dashboard === DASHBOARDS.DASHBOARD_ADMIN) {
            return <SupervisorAccount/>
        } else if (dashboard === DASHBOARDS.DASHBOARD_MARKETPLACE) {
            return <MarketplaceIcon/>
        } else {
            return <Help/>;
        }
    }

    return (
        <Avatar alt={alt} className={classes.iconContainer} style={{backgroundColor: getColor()}}>
            {getIcon()}
        </Avatar>
    );
}

const ItemDashboard = ({dashboard, onClick}) => {

    const getTitle = () => {
        if (dashboard === DASHBOARDS.DASHBOARD_CREATOR) {
            return "Team dashboard";
        } else if (dashboard === DASHBOARDS.DASHBOARD_BRAND) {
            return "Brand dashboard";
        } else if (dashboard === DASHBOARDS.DASHBOARD_ADMIN) {
            return "Admin dashboard";
        } else if (dashboard === DASHBOARDS.DASHBOARD_MARKETPLACE) {
            return "Influence dashboard";
        } else {
            return "Unknown dashboard";
        }
    }

    return (
        <ListItem button onClick={onClick}>
            <ListItemAvatar>
                <DashboardIcon dashboard={dashboard} alt={getTitle(dashboard)}/>
            </ListItemAvatar>
            <ListItemText primary={getTitle(dashboard)}/>
        </ListItem>
    )
}

export default DashboardSelector;
