import {createSlice} from '@reduxjs/toolkit';
import api from '../helpers/api';
import RequestError from '../helpers/RequestError';
import roleHelper from "../helpers/roleHelper";

export const initialState = {
    user: null,
    isAuthenticated: null,
    loginError: false,
    loginErrorMessage: '',
    loginNotificationType: "error",
    email: '',
    campaignSession: null
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginSuccess: (state, {payload}) => {
            state.user = payload;
            state.isAuthenticated = true;
        },
        loginFailure: (state, {payload}) => {
            state.loginError = true;
            state.loginErrorMessage = payload;
            state.loginNotificationType = "error";
        },
        loginNotification: (state, {payload}) => {
            state.loginError = true;
            state.loginErrorMessage = payload;
            state.loginNotificationType = "info";
        },
        setAuthenticated: (state, {payload}) => {
            state.user = payload;
            state.isAuthenticated = true;
        },
        getMeFailure: (state, {payload}) => {
            if (payload.status === 401) {
                state.isAuthenticated = false;
            } else {
                state.loginError = true;
                state.loginErrorMessage = payload.message;
            }
        },
        setTypedEmail: (state, {payload}) => {
            state.email = payload;
        },
        clearError: (state) => {
            state.loginError = false;
        },
        setCampaignSession: (state,{payload}) => {
            state.campaignSession = payload;
        }
    },
});

export const loginSelector = state => state.login;
export default loginSlice.reducer;

const {setCampaignSession, loginSuccess, loginFailure, loginNotification, setAuthenticated, getMeFailure, setTypedEmail, clearError} = loginSlice.actions;

export const login = ({email, password}) => dispatch  => api.login()(
    response => api.getUserServiceResource(response.headers.location)(
        ({data}) => {
            const lastInactiveRole = roleHelper.getLastInactiveRole(data);
            const clientUserRoles = roleHelper.getActiveClientUserRoles(data);
            if (lastInactiveRole && clientUserRoles.length === 0) {
                const inactiveAccountMessage = roleHelper.getInactiveAccountMessage(data);
                if (inactiveAccountMessage.type === "info") {
                    dispatch(loginNotification(inactiveAccountMessage.message));
                } else {
                    dispatch(loginFailure(inactiveAccountMessage.message));
                }
            } else {
                dispatch(loginSuccess(data));
            }
        },
        err => {
            const message = (new RequestError(err)).getMessage();
            dispatch(loginFailure(message));
        }
    ),
    error => {
        const message = (new RequestError(error)).getMessage();
        dispatch(loginFailure(message));
    }
)({email, password});

export const getMe = () => dispatch  => api.getMe()(
    response => dispatch(setAuthenticated(response.data)),
    error => {
        const respError = new RequestError(error)
        dispatch(getMeFailure({
            status: respError.getHttpStatusCode(),
            message: respError.getMessage()
        }));
    }
);

export const setEmail = (email) => dispatch => dispatch(setTypedEmail(email));

export const clearLoginError = () => dispatch => dispatch(clearError());

export const setLoginFromCampaign = (payload) => dispatch => dispatch(setCampaignSession(payload));