import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    normal: {
    },
    disabled: {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        borderBottomColor: "rgba(0, 0, 0, 0.26)",
        color: "rgba(0, 0, 0, 0.26)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            borderBottomColor: "rgba(0, 0, 0, 0.26)",
            color: "rgba(0, 0, 0, 0.26)",
        },
    },
}));

const CustomButton = ({children, looksDisabled, ...props}) => {
    const styles = useStyles();

    return (
        <Button variant="contained" color="primary" {...props} className={looksDisabled ? styles.disabled : styles.normal} >
            {children}
        </Button>
    )
}

export default CustomButton;