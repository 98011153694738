import React, {createContext} from 'react';

const ClientConfigContext = createContext({name: 'ClientConfig'});
const {Consumer, Provider} = ClientConfigContext;

const withClientConfig = (WrappedComponent) => (props) => {
    return (
        <Consumer>
            {clientConfig => <WrappedComponent clientConfig={clientConfig} {...props}/>}
        </Consumer>
    )
}

export {withClientConfig};
export default Provider;