import React from 'react';
import "./ConnectButton.scss";
import Button from "@material-ui/core/Button";

const GoogleConnectButton = ({onClick, title, icon, disabled}) => {
    return (
        <Button onClick={onClick} startIcon={icon} fullWidth variant={"outlined"} disabled={disabled}>
            {title}
        </Button>
    );
}

export default GoogleConnectButton;