const urlHelper = {
    isAllowedHost: (host) => /.+\.creatable\.io$/g.test(host),

    isValidUrl: (url) => {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    },

    getClientHash: (urlString) => {
        if (urlHelper.isLegacyRedirect(urlString)) {
            const pathParts = urlString.split('/');
            return pathParts[2] ?? null;
        } else if (!urlHelper.isValidUrl(urlString)) {
            return null;
        }
        const url = new URL(urlString);
        if (!urlHelper.isAllowedHost(url.host)) {
            return null;
        }
        const pathParts = url.pathname.split('/');
        const hostParts = url.hostname.split('.');
        if (["brand", "creator", "creators", "campaigns"].includes(hostParts[0]) && pathParts[1] && pathParts[1] !== "marketplace") {
            return pathParts[1];
        } else {
            return null;
        }
    },

    isLegacyRedirect: (redirectUrl) => /^\/ambassadors\/.+/.test(redirectUrl),
}

export default urlHelper;