import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 0,
        '& li': {
            padding: 0,
            '& div:first-child': {
                minWidth: 30,
                width: 30,
            }
        }
    },
    grayIconColor: {
        fill: '#e0e0e0',
    },
    greenIconColor: {
        fill: theme.palette.success.dark,
    },
    grayTextColor: {
        color: '#a0a0a0',
    },
    greenTextColor: {
        color: "#357a38",
    },
}));

const PasswordValidator = ({password, onValidate}) => {
    const classes = useStyles();

    const isEmpty = password.trim() === '';
    const isCaseValid = /(?=.*[a-z])(?=.*[A-Z])/.test(password);
    const isSpecialsValid = /(?=.*[$@$!%*?&])/.test(password);
    const isLengthValid = password.trim().length >= 8;

    const caseIconColor = isCaseValid ? classes.greenIconColor : classes.grayIconColor;
    const specialsIconColor = isSpecialsValid ? classes.greenIconColor : classes.grayIconColor;
    const lengthIconColor = isLengthValid ? classes.greenIconColor : classes.grayIconColor;

    const caseTextColor = isCaseValid ? classes.greenTextColor : classes.grayTextColor;
    const specialsTextColor = isSpecialsValid ? classes.greenTextColor : classes.grayTextColor;
    const lengthTextColor = isLengthValid ? classes.greenTextColor : classes.grayTextColor;

    const intl = useIntl();
    const caseText = intl.formatMessage({
        id: 'PasswordValidator.OneUppercaseOneLowercase',
        defaultMessage: 'One uppercase, one lowercase letter'
    });
    const specialsText = intl.formatMessage({
        id: 'PasswordValidator.OneSpecialChar',
        defaultMessage: 'At least 1 special character'
    });
    const lengthText = intl.formatMessage({
        id: 'PasswordValidator.MinimumEightCharacters',
        defaultMessage: 'At least 8 characters long'
    });

    useEffect(() => {
        const isValid = !isEmpty && isCaseValid && isSpecialsValid && isLengthValid;
        onValidate(isValid);
    }, [isEmpty, isCaseValid, isSpecialsValid, isLengthValid, onValidate]);

    return (
        <List dense={true} className={classes.root}>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon className={caseIconColor} fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={caseText} className={caseTextColor}/>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon className={specialsIconColor} fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={specialsText} className={specialsTextColor}/>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon className={lengthIconColor} fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary={lengthText} className={lengthTextColor}/>
            </ListItem>
        </List>
    );
}

export default PasswordValidator;
