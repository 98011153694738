import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    inProgress: false,
};

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setBasicInfo: (state, {payload}) => {
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.email = payload.email;
        },
        setPassword: (state, {payload}) => {
            state.password = payload;
        },
        setInProgress: (state, {payload}) => {
            state.inProgress = payload;
        },
    },
});

export const onboardingSelector = state => state.onboarding;
export default onboardingSlice.reducer;

export const {setBasicInfo, setPassword, setInProgress} = onboardingSlice.actions;