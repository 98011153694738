import React, {useCallback, useEffect} from "react";
import routes from "../helpers/routes";
import {ROLES} from "../helpers/roleHelper";
import {getMe, loginSelector} from "../slices/login";
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../slices/notification";
import LoadingApp from "../components/LoadingApp";
import Api from "../helpers/api";
import RequestError from "../helpers/RequestError";

const userHasNetworkRole = (user) => !!user?.roles.find(r => r?.role?.role_id === ROLES.ROLE_MARKETPLACE);

const SignUpSuccess = () => {
    const dispatch = useDispatch();
    const {isAuthenticated, user, loginError, loginErrorMessage} = useSelector(loginSelector);

    const createRole = useCallback(() => {
        Api.createRole(user.user_id)(
            () => dispatch(getMe()),
            (error) => dispatch(setNotification((new RequestError(error)).getMessage(), "error"))
        )({role_id: ROLES.ROLE_MARKETPLACE});
    }, [user, dispatch]);

    useEffect(() => {
        if (isAuthenticated === null) {
            dispatch(getMe());
        }
    }, [isAuthenticated, dispatch]);

    useEffect(() => {
        if (loginError) {
            dispatch(setNotification(loginErrorMessage, "error"));
        }
    }, [loginError, dispatch, loginErrorMessage]);

    useEffect(() => {
        if (user && userHasNetworkRole(user)) {
            window.location.href = routes.getLoginUrl();
        } else if (user && !userHasNetworkRole(user)) {
            createRole();
        } else if (user) {
            window.location.href = routes.getLoginUrl();
        }
    }, [user, createRole]);

    return <LoadingApp/>;
}

export default SignUpSuccess;
