import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import PasswordInput from "../PasswordInput";
import PasswordValidator from "../PasswordValidator";
import React, {Fragment, useEffect, useRef, useState} from "react";
import Api from "../../helpers/api";
import useOnboarding from "../../helpers/useOnboarding";
import useNotification from "../../helpers/useNotification";
import roleHelper, {ROLES} from "../../helpers/roleHelper";
import PrimaryButton from "../CustomButton";
import {isEmpty} from "../../helpers/utils";

const SetPasswordStep = ({onSuccess}) => {
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();

    const notification = useNotification();
    const {
        login,
        reloadUser,
        loginError,
        loginErrorMessage,
        firstName,
        lastName,
        email,
        inProgress,
        setInProgress,
        user,
    } = useOnboarding();

    const [showErrors, setShowErrors] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);

    useEffect(() => {
        passwordRef?.current?.focus();
    }, []);

    useEffect(() => {
        if (loginError) {
            notification.error(loginErrorMessage);
        }
    }, [loginError, loginErrorMessage, notification]);

    const onChangePassword = (event) => {
        setIsPasswordMatch(event.target.value === confirmPassword);
        setPassword(event.target.value);
    };

    const onChangeConfirmPassword = (event) => {
        setIsPasswordMatch(event.target.value === password);
        setConfirmPassword(event.target.value);
    };

    const onClickCreateAccount = () => {
        if (!isPasswordValid) {
            setShowErrors(true);
        } else if (!isPasswordMatch) {
            setShowErrors(true);
            notification.error("Password do not match");
        } else {
            setInProgress(true);
            setShowErrors(false);
            if (!user || user.email !== email) {
                Api.createAccount()(
                    () => {
                        createRole()
                    },
                    (error) => {
                        setInProgress(false);
                        notification.captureError(error);
                    }
                )({first_name: firstName, last_name: lastName, email, password});
            } else if (!roleHelper.getMarketplaceRole(user)) {
                createRole();
            } else {
                onSuccess();
            }
        }
    };

    const createRole = () => {
        setInProgress(true);
        login({email, password}).then(() => {
            reloadUser().then(({data}) => {
                Api.createRole(data.user_id)(
                    () => {
                        reloadUser();
                        setInProgress(false);
                        onSuccess();
                    },
                    (error) => {
                        notification.captureError(error);
                        setInProgress(false);
                    }
                )({role_id: ROLES.ROLE_MARKETPLACE});
            });
        }).catch((error) => {
            notification.captureError(error);
            setInProgress(false);
        });
    }

    const onPressEnter = (callback) => (e) => {
        if (e.key === 'Enter') {
            callback();
        }
    };

    const getButtonLooksDisabled = () => {
        return (isEmpty(password) || isEmpty(confirmPassword) ||!isPasswordValid || (showErrors && !isPasswordMatch));
    }

    return (
        <Fragment>
            <Box mt={2} mb={1}>
                <FormControl fullWidth margin={"dense"}>
                    <PasswordInput error={(isPasswordValid || !isPasswordMatch) && showErrors}
                                   required
                                   errorText={!isPasswordValid ? "Password is not valid." : ""}
                                   label={"Password"}
                                   disabled={inProgress}
                                   inputRef={passwordRef}
                                   onKeyUp={onPressEnter(() => confirmPasswordRef?.current?.focus())}
                                   onChange={onChangePassword}/>
                </FormControl>
                <FormControl fullWidth margin={"dense"}>
                    <PasswordInput error={!isPasswordMatch && showErrors}
                                   required
                                   errorText={"Password and confirm password must match!"}
                                   label={"Confirm password"}
                                   inputRef={confirmPasswordRef}
                                   disabled={inProgress}
                                   onKeyUp={onPressEnter(onClickCreateAccount)}
                                   onChange={onChangeConfirmPassword}/>
                </FormControl>
                <PasswordValidator password={password} onValidate={setIsPasswordValid}/>
            </Box>


            <Box>
                <PrimaryButton onClick={onClickCreateAccount}
                               disabled={inProgress}
                               looksDisabled={getButtonLooksDisabled()}
                               fullWidth>
                    Continue
                </PrimaryButton>
            </Box>
        </Fragment>
    )
}

export default SetPasswordStep;