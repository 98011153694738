import React, {Children} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import AppLogo from "./AppLogo";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {isEmpty} from "../helpers/utils";
import {Container} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: '#f8f9fa',
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        minHeight: "100vh",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: '400px',
    },
    gridItem: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    paper: {
        width: "100%",
        overflow: 'hidden',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    logo: {
        width: "25px",
    },
    appLogo: {
        width: "75%"
    }
}));

const CenteredBox = ({inProgress, showBackdrop, showLogo, logoUrl, title, subtitle, children, footer}) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Container className={classes.container}>
                <Paper elevation={3} className={classes.paper}>
                    <Box p={2}>
                        {
                            showLogo &&
                            <Box justifyContent="center" display="flex">
                                {logoUrl && <img src={logoUrl} className={classes.logo} alt={"Creatable"}/>}
                                {!logoUrl && <div className={classes.appLogo}><AppLogo/></div>}
                            </Box>
                        }

                        {
                            !isEmpty(title) &&
                            <Box mb={1}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    {title}
                                </Typography>
                            </Box>
                        }

                        {
                            !isEmpty(subtitle) &&
                            <Box mb={2}>
                                <Typography variant="body2" align="center" gutterBottom>
                                    {subtitle}
                                </Typography>
                            </Box>
                        }

                        {Children.toArray(children)}
                    </Box>
                    {inProgress && <LinearProgress/>}
                    <Backdrop className={classes.backdrop} open={!!showBackdrop}/>
                </Paper>
                <Box className={classes.gridItem}>
                    {footer}
                </Box>
            </Container>
        </Box>
    )
}

export default CenteredBox;
