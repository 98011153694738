import React, {Fragment, useEffect, useState} from 'react';
import {
    Switch,
    Route, withRouter, Redirect
} from 'react-router-dom';
import LoginError from './containers/LoginError';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import {withCustomTheme} from './providers/CustomThemeProvider';
import withNetworkDetector from './hoc/withNetworkDetector';
import withApplicationStatusDetector from './hoc/withApplicationStatusDetector';
import Logout from "./containers/Logout";
import qs from "qs";
import Api from "./helpers/api";
import LoadingApp from "./components/LoadingApp";
import routes from "./helpers/routes";
import MainNotification from "./components/MainNotification";
import {useDispatch} from "react-redux";
import SignUpSuccess from "./containers/SignUpSuccess";
import UserRedirectFlow from "./containers/UserRedirectFlow";
import SsoError from "./containers/SsoError";
import AzureSSO from "./containers/AzureSSO";
import OktaSSO from "./containers/OktaSSO";
import BrandOnboarding from "./containers/BrandOnboarding";
import BrandAccountCreated from "./containers/BrandAccountCreated";
import BrandOnboardingValidation from "./containers/BrandOnboardingValidation";
import SignUp from "./containers/SignUp";
import BookNow from "./containers/BookNow";

const App = ({location: {search}}) => {
    const dispatch = useDispatch();
    const [inProgress, setInProgress] = useState(true);

    useEffect(() => {
        const queryParams = qs.parse(search, {ignoreQueryPrefix: true});
        if (queryParams?.authToken || queryParams?.auth_token) {
            const token = queryParams.hasOwnProperty('authToken') ? queryParams.authToken : queryParams.auth_token;
            Api.tokenAuthentication()(
                () => {
                    setInProgress(false)
                },
                (error) => {
                    setInProgress(false);
                }
            )({auth_token: token});
        } else {
            setInProgress(false);
        }
    }, [search, dispatch]);

    return (
        <>
            <AppStatusNotifier/>
            {inProgress && <LoadingApp/>}
            {
                !inProgress &&
                <Switch>
                    <Route path="/logout" exact>
                        <Logout/>
                    </Route>
                    <Route path={"/"} exact>
                        <UserRedirectFlow/>
                    </Route>
                    <Route path="/error" exact>
                        <LoginError/>
                    </Route>
                    <Route path="/forgot-password" exact>
                        <ForgotPassword/>
                    </Route>
                    <Route path="/reset-password/:token" exact>
                        <ResetPassword/>
                    </Route>
                    <Route path={routes.getSignUpUrl()}>
                        <SignUp/>
                    </Route>
                    <Route path={"/dashboards"} exact>
                        <Redirect to={"/"}/>
                    </Route>
                    <Route path={routes.getLoginSuccessUrl()} exact>
                        <Redirect to={"/"}/>
                    </Route>
                    <Route path={routes.getOAuthSignUpSuccessUrl()} exact>
                        <SignUpSuccess/>
                    </Route>
                    <Route path={routes.getSignUpSuccessUrl()} exact>
                        <SignUpSuccess/>
                    </Route>
                    <Route path={routes.getSsoErrorUrl()} exact>
                        <SsoError/>
                    </Route>
                    <Route path={routes.getAzureSSOUrl()} exact>
                        <AzureSSO/>
                    </Route>
                    <Route path={routes.getOktaSSOUrl()} exact>
                        <OktaSSO/>
                    </Route>
                    <Route path={'/brand/signup'} exact>
                        <BrandOnboarding />
                    </Route>
                    <Route path={'/brand/account-activated'} exact>
                        <BrandAccountCreated />
                    </Route>
                    <Route path={'/client-onboarding'} exact>
                        <BrandOnboardingValidation />
                    </Route>
                    <Route path={'/book-now/:reference'} exact>
                        <BookNow />
                    </Route>
                    <Redirect from="*" to="/" />
                </Switch>
            }
            <MainNotification/>
        </>
    );
}

const AppStatusNotifier = withNetworkDetector(withApplicationStatusDetector(() => {
    return <Fragment/>;
}));

export default withCustomTheme(withRouter(App));
