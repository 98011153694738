import config from '../config';
import * as Sentry from '@sentry/browser';

const sentry = {

    init: () => {
        if (config.sentry.active) {
            Sentry.init(config.sentry);
        }
    },

    captureException: (error) => {
        if (config.sentry.active) {
            Sentry.captureException(error);
        }
    },

    captureAxiosException: (error) => {
        if (config.sentry.active) {
            Sentry.withScope(scope => {
                if (error.response) {
                    const {config, headers, data, status, statusText} = error.response;
                    scope.setExtra("response", {config, headers, data, status, statusText});
                    Sentry.captureException(error);
                } else if (error.request) {
                    const {url, baseUrl, method, headers, params, data} = error.request;
                    scope.setExtra("request", {url, baseUrl, method, headers, params, data});
                    Sentry.captureException(error);
                } else {
                    Sentry.captureException(error);
                }
            });
        }
    },
    
}

export default sentry;