import React, {useState} from "react";
import CenteredBox from "../CenteredBox";
import SignUpFooter from "./SignUpFooter";
import PrimaryButton from "../CustomButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AccountTypeButton from "./AccountTypeButton";
import {isAndroid, isIOS} from "react-device-detect";

const ACCOUNT_TYPE_CREATOR = "creator";
const ACCOUNT_TYPE_BRAND = "brand";

const SignUpStart = ({onSelectAccountType}) => {
    const [accountType, setAccountType] = useState((isAndroid || isIOS) ? ACCOUNT_TYPE_CREATOR : ACCOUNT_TYPE_BRAND);

    const getButtonLooksDisabled = () => {
        return accountType === null;
    }

    return (
        <>
            <CenteredBox title={"Sign up"} showLogo footer={<SignUpFooter/>}>
                <Box mt={2} mb={2}>
                    <Grid container spacing={2} direction={(isAndroid || isIOS) ? "row" : "row-reverse"}>
                        <Grid item xs={6}>
                            <AccountTypeButton type={ACCOUNT_TYPE_CREATOR}
                                               selected={ACCOUNT_TYPE_CREATOR === accountType}
                                               onClick={() => setAccountType(ACCOUNT_TYPE_CREATOR)}/>
                        </Grid>
                        <Grid item xs={6}>
                            <AccountTypeButton type={ACCOUNT_TYPE_BRAND} selected={ACCOUNT_TYPE_BRAND === accountType}
                                               onClick={() => setAccountType(ACCOUNT_TYPE_BRAND)}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    {
                        accountType === ACCOUNT_TYPE_CREATOR &&
                        <Typography variant={"body1"}>
                            Work with top brands to sell their products by posting on social media.
                        </Typography>
                    }
                    {
                        accountType === ACCOUNT_TYPE_BRAND &&
                        <Typography variant={"body1"}>
                            Book creators to sell your products with content on social media and ecommerce product pages.
                        </Typography>
                    }
                </Box>
                <PrimaryButton onClick={() => onSelectAccountType(accountType)}
                               looksDisabled={getButtonLooksDisabled()}
                               fullWidth>
                    Next
                </PrimaryButton>
            </CenteredBox>
        </>
    )
}

export default SignUpStart;

