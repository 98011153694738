import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    info: {
        backgroundColor: '#0288D1',
        borderRadius: 12,
    },
    alert: {
        borderRadius: 12,
    }
}));

const TopNotification = ({severity, message, open}) => {
    const styles = useStyles();
    return (
        <Snackbar elevation={3} anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={open}>
            <MuiAlert severity={severity} variant="filled" className={severity === "info" ? styles.info : styles.alert}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default TopNotification;