import React from 'react';
import {withRouter} from 'react-router-dom';
import routes from '../helpers/routes';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {FormattedMessage} from 'react-intl';

import AppLogo from '../components/AppLogo';
import {withClientConfig} from '../providers/ClientConfigProvider';

import {makeStyles} from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

const Sso = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center"
              className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={3}>

                    <Box mt={2} mb={2} justifyContent="center" display="flex">
                        <div className={classes.app_logo}><AppLogo/></div>
                    </Box>

                    <Typography variant="h5" align="center" gutterBottom>
                        <FormattedMessage id="Login.SignIn" defaultMessage="Log in"/>
                    </Typography>

                    <Typography variant={"body1"} align={"center"}>
                        We were unable to identify your SSO provider, please contact {" "}
                        <Link href="mailto:support@creatable.io" color="primary">
                            support@creatable.io
                        </Link>
                    </Typography>
                </Box>
            </Paper>

            <Box mt={4}>
                <Typography variant="body2" align="center" style={{fontWeight: 500}}>
                    <Link component={RouterLink} to={routes.getLoginUrl()}>
                        ← Back
                    </Link>
                </Typography>
            </Box>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    app_logo: {
        width: "75%"
    }
}));

export default withClientConfig(withRouter(Sso));
