import React, {useEffect, useState} from 'react';

import {IntlProvider} from 'react-intl';
import detectBrowserLanguage from 'detect-browser-language';
import messages from './translations';

import {configureStore} from '@reduxjs/toolkit';
import {Provider as ReduxProvider} from 'react-redux';
import ClientConfigProvider from './providers/ClientConfigProvider';
import CustomThemeProvider from './providers/CustomThemeProvider';
import {CookiesProvider, useCookies} from "react-cookie";
import createTheme from './helpers/createTheme';
import rootReducer from './slices';
import {isURL, object as ObjectUtils} from './helpers/utils';
import LoadingApp from "./components/LoadingApp";

import App from './App';
import qs from "qs";
import Api from "./helpers/api";
import environment from "./helpers/environment";
import {BrowserRouter as Router} from 'react-router-dom';

const store = configureStore({reducer: rootReducer});
const locale = detectBrowserLanguage().split('-')[0];

const getClientHashFromContinueUrl = () => {

    const continueParam = getContinueUrl();
    const validateUrl = isURL(continueParam);
    const urlPart = validateUrl ? validateUrl.pathname : continueParam;
    if (!validateUrl && /\/ambassadors\//.test(urlPart) && urlPart.split('/ambassadors/').length > 1) {
        const clientHash = urlPart.split('/ambassadors/')[1].split('/')[0];
        return clientHash !== "" ? clientHash : null;
    } else if (validateUrl && validateUrl.hostname.includes('.creatable.io')) {
        const clientHash = urlPart.split('/')[1];
        return clientHash !== "" ? clientHash : null;
    }
    else {
        return null;
    }
}

const getContinueUrl = () => {
    const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    return queryParams.hasOwnProperty('continue') ? queryParams.continue : '';
}

const isSecure = () => window.location.protocol === "https";

const Root = () => {
    const [isClientConfigLoading, setIsClientConfigLoading] = useState(true);
    const [clientConfig, setClientConfig] = useState(null);
    const [isCustomThemeLoading, setIsCustomThemeLoading] = useState(true);
    const [customTheme, setCustomTheme] = useState({});
    const [, , removeCookie] = useCookies(['tvp_user_hash', 'tvp_impersonation_user_hashes']);

    useEffect(() => {
        removeCookie("tvp_user_hash", {secure: isSecure(), domain: `.${environment.getUserServiceHost()}`});
        removeCookie("tvp_impersonation_user_hashes", {
            secure: isSecure(),
            domain: `.${environment.getUserServiceHost()}`
        });
    }, [removeCookie])

    useEffect(() => {
        const clientHash = getClientHashFromContinueUrl();
        if (clientHash) {
            Api.getClientConfigByClientHash(clientHash)(
                (response) => {
                    setClientConfig(response.data);
                    setIsClientConfigLoading(false);
                },
                () => setIsClientConfigLoading(false)
            );
        } else {
            setIsClientConfigLoading(false);
        }
    }, []);

    useEffect(() => {
        if (isClientConfigLoading === false) {
            setCustomTheme(createTheme(ObjectUtils.get('dashboards.ambassador.theme', clientConfig, {})));
            setIsCustomThemeLoading(false);
        }
    }, [clientConfig, isClientConfigLoading]);

    if (isClientConfigLoading || isCustomThemeLoading) {
        return (
            <LoadingApp/>
        );
    } else {
        return (
            <ReduxProvider store={store}>
                <IntlProvider locale={locale} defaultLocale="en" messages={messages[locale]}>
                    <ClientConfigProvider value={clientConfig}>
                        <CustomThemeProvider value={customTheme}>
                            <CookiesProvider>
                                <Router>
                                    <App/>
                                </Router>
                            </CookiesProvider>
                        </CustomThemeProvider>
                    </ClientConfigProvider>
                </IntlProvider>
            </ReduxProvider>
        );
    }
}

export default Root;
