import {useDispatch, useSelector} from "react-redux";
import {getMe, login, loginSelector} from "../slices/login";
import {setBasicInfo, setPassword, setInProgress, onboardingSelector} from "../slices/onboarding";

const useOnboarding = () => {
    const dispatch = useDispatch();
    const {user, loginError, loginErrorMessage} = useSelector(loginSelector);
    const {inProgress, firstName, lastName, email} = useSelector(onboardingSelector);

    const reloadUser = () => dispatch(getMe());

    return {
        user,
        firstName,
        lastName,
        email,
        loginError,
        loginErrorMessage,
        inProgress,
        login: (params) => dispatch(login(params)),
        setBasicInfo: ({firstName, lastName, email}) => dispatch(setBasicInfo({firstName, lastName, email})),
        setPassword: (password) => dispatch(setPassword(password)),
        setInProgress: (inProgress) => dispatch(setInProgress(inProgress)),
        reloadUser,
    };
};

export default useOnboarding;
