import environment, {ENV_NAMES} from '../helpers/environment';
import localConfig from './config.local.js';
import testConfig from './config.test.js';
import stageConfig from './config.stage.js';
import prodConfig from './config.prod.js';

const getConfigByEnvironment = () => {
    switch (environment.getName()) {
        case ENV_NAMES.LOCAL:
            return localConfig;
        case ENV_NAMES.TEST:
            return testConfig;
        case ENV_NAMES.STAGE:
            return stageConfig;
        case ENV_NAMES.PROD:
            return prodConfig;
        default:
            return {};
    }
}

const config = {
    ...getConfigByEnvironment()
}

export default config;