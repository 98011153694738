import {combineReducers} from 'redux';

import login from './login';
import notification from './notification';
import onboarding from "./onboarding";

const rootReducer = combineReducers({
    login,
    notification,
    onboarding,
})

export default rootReducer