import React, {useEffect, useState, Fragment} from 'react';
import {withRouter} from 'react-router';
import useLocalStorage from 'react-use-localstorage';
import qs from 'qs';
import routes from '../helpers/routes';

import {strings} from "../helpers/utils";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {FormattedMessage} from 'react-intl';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {makeStyles} from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
        padding: theme.spacing(4, 0),
    },
}));

const errorStatus = ['user_disabled', 'oauth_error', 'oauth_email_not_found'];

const LoginError = (props) => {
    const classes = useStyles();
    const [oauthProvider] = useLocalStorage('oauthProvider', null);
    const [currentStatus, setCurrentStatus] = useState('generic_error');

    const {location: {search}, history} = props;
    const providerName = strings.capitalize(oauthProvider);

    useEffect(() => {
        const queryParams = qs.parse(search, {ignoreQueryPrefix: true});
        if (queryParams.hasOwnProperty('code')) {
            const validStatus = errorStatus.includes(queryParams.code) ? queryParams.code : 'generic_error';
            const newStatus = validStatus === 'oauth_error' && oauthProvider === null ? 'generic_error' : validStatus;
            setCurrentStatus(newStatus);
            history.push(routes.getErrorUrl());
        }
    }, [search, setCurrentStatus, history, oauthProvider]);

    const handleClickBackToLogin = (event) => {
        props.history.push(routes.getLoginUrl());
    };

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.root}>
            <Paper elevation={3} className={classes.paper}>
                <Box px={3}>

                    <Box>
                        {
                            currentStatus === 'oauth_error' &&
                            <Fragment>
                                <Box mb={4}>
                                    <Typography variant="h5" align="center" gutterBottom>
                                        <FormattedMessage
                                            id="Login.AuthenticationErrorTitle"
                                            defaultMessage="Authentication Error" />
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography align="center" gutterBottom>
                                        <FormattedMessage 
                                            id="Login.OauthLoginError"
                                            defaultMessage="There was an error authenticating with your {provider} account." values={{provider: providerName}}/>
                                    </Typography>
                                </Box>
                                <Box mt={3}>
                                    <Typography align="center" gutterBottom>
                                        <FormattedMessage 
                                            id="Login.TryAgainOrContactSupport"
                                            defaultMessage="Please try again or contact support."/>
                                    </Typography>
                                </Box>
                            </Fragment>
                        }

                        {
                            currentStatus === 'generic_error' &&
                            <Fragment>
                                <Box mb={4}>
                                    <Typography variant="h5" align="center" gutterBottom>
                                        <FormattedMessage
                                            id="Login.AuthenticationErrorTitle"
                                            defaultMessage="Authentication Error" />
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography align="center" gutterBottom>
                                        <FormattedMessage 
                                            id="Login.GenericLoginError"
                                            defaultMessage="There was an error with authentication process." />
                                    </Typography>
                                </Box>
                                <Box mt={3}>
                                    <Typography align="center" gutterBottom>
                                        <FormattedMessage 
                                            id="Login.TryAgainOrContactSupport"
                                            defaultMessage="Please try again or contact support."/>
                                    </Typography>
                                </Box>
                            </Fragment>
                        }

                        {
                            currentStatus === 'user_disabled' &&
                            <Fragment>
                                <Box mb={4}>
                                    <Typography variant="h5" align="center" gutterBottom>
                                        <FormattedMessage
                                            id="Login.SuspendedAccount"
                                            defaultMessage="Suspended Account" />
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography align="center" gutterBottom>
                                        <FormattedMessage 
                                            id="Login.UserDisabledMessage"
                                            defaultMessage="Your account has been suspended, please contact the program administrator with any questions." />
                                    </Typography>
                                </Box>
                            </Fragment>
                        }

                        {
                            currentStatus === 'oauth_email_not_found' &&
                            <Fragment>
                                <Box mb={4}>
                                    <Typography variant="h5" align="center" gutterBottom>
                                        <FormattedMessage
                                            id="Login.UnableToSignIn"
                                            defaultMessage="Unable to sign in" />
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography align="center" gutterBottom>
                                        <FormattedMessage
                                            id="Login.OauthEmailNotFound"
                                            defaultMessage="We were unable to sign you in with this {provider} account, please check that the account used to sign in, is the correct one. If the problem persists, please contact {email}."
                                            values={{
                                                provider: providerName,
                                                email: (
                                                    <Link href="mailto:support@creatable.io" color="primary">
                                                        support@creatable.io
                                                    </Link>
                                                )
                                            }}/>
                                    </Typography>
                                </Box>
                            </Fragment>
                        }
                    </Box>

                    {
                        currentStatus !== 'user_disabled' && 
                        <Box mt={4}>
                            <Button color="primary" onClick={handleClickBackToLogin} fullWidth startIcon={<ArrowBackIcon/>}>
                                <FormattedMessage
                                    id="Login.BackToSignIn"
                                    defaultMessage="Back to sign in" />
                            </Button>
                        </Box>
                    }

                </Box>
            </Paper>
        </Grid>
    );
}

export default withRouter(LoginError);