import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GoogleIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" id="Layer_1"
                 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 19 19.2">
                <g id="Google-Button">
                    <g id="btn_google_light_normal" transform="translate(-1.000000, -1.000000)">
                        <g id="logo_googleg_48dp" transform="translate(15.000000, 15.000000)">
                            <path id="Shape" fillRule="evenodd" clipRule="evenodd" fill="#4285F4" d="M4.2-4.2c0-0.6-0.1-1.3-0.2-1.8h-8.5v3.5h4.8
				C0.2-1.4-0.4-0.5-1.4,0.2v2.3h2.9C3.3,0.8,4.2-1.5,4.2-4.2L4.2-4.2z"/>
                            <path id="Shape_1_" fillRule="evenodd" clipRule="evenodd" fill="#34A853" d="M-4.4,4.6c2.4,0,4.5-0.8,6-2.2l-2.9-2.3C-2.2,0.7-3.2,1-4.4,1
				c-2.3,0-4.3-1.6-5-3.7h-3v2.3C-11,2.6-7.9,4.6-4.4,4.6L-4.4,4.6z"/>
                            <path id="Shape_2_" fillRule="evenodd" clipRule="evenodd" fill="#FBBC05" d="M-9.4-2.7c-0.2-0.5-0.3-1.1-0.3-1.7s0.1-1.2,0.3-1.7v-2.3h-3
				c-0.6,1.2-1,2.6-1,4s0.3,2.8,1,4L-9.4-2.7L-9.4-2.7z"/>
                            <path id="Shape_3_" fillRule="evenodd" clipRule="evenodd" fill="#EA4335" d="M-4.4-9.8c1.3,0,2.5,0.5,3.4,1.3l2.6-2.6c-1.6-1.5-3.6-2.3-6-2.3
				c-3.5,0-6.6,2-8,5l3,2.3C-8.7-8.2-6.7-9.8-4.4-9.8L-4.4-9.8z"/>
                            <path id="Shape_4_" fill="none" d="M-13.4-13.4h18v18h-18V-13.4z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default GoogleIcon;