import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {loginSelector} from '../slices/login';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {FormattedMessage} from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import {makeStyles} from '@material-ui/core/styles';
import roleHelper, {DASHBOARDS} from "../helpers/roleHelper";
import Api from "../helpers/api";
import {ArrowBack} from "@material-ui/icons";
import routes from "../helpers/routes";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#f8f9fa'
    },
    paper: {
        width: '95%',
        maxWidth: '350px',
        overflow: 'hidden',
    },
    list: {
        height: 'calc(100vh - 120px)',
        maxHeight: '300px',
        overflowY: 'auto',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
}));

const ClientSelector = ({onClickClient, onClickLogout, dashboard, isBackEnabled, onClickBack}) => {
    const classes = useStyles();
    const {user, isAuthenticated} = useSelector(loginSelector);

    const [showSpinner, setShowSpinner] = useState(true);
    const [clients, setClients] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            const creatorRoles = roleHelper.getActiveClientUserRoles(user);
            const brandAdminRoles = roleHelper.getActiveBrandAdminRoles(user);
            if (DASHBOARDS.DASHBOARD_CREATOR === dashboard && creatorRoles.length === 1 && creatorRoles[0]?.client) {
                window.location.href = routes.geCreatorsAppUrl(`/${creatorRoles[0].client.hash}/campaigns`);
            } else if (DASHBOARDS.DASHBOARD_BRAND === dashboard && brandAdminRoles.length === 1 && brandAdminRoles[0]?.client) {
                window.location.href = routes.getDefaultBrandDashboardUrl(creatorRoles[0].client.hash);
            } else {
                setShowSpinner(false);
                setIsInitialized(true);
            }
        } else {
            setShowSpinner(true);
        }
    }, [isAuthenticated, user, dashboard]);

    useEffect(() => {
        if (user && dashboard) {
            const clientsForDashboard = roleHelper.getActiveClientUserRoleFromUserAndDashboard(user, dashboard);
            setClients(clientsForDashboard);
        } else if (user) {
            setClients(roleHelper.getActiveClientsFromUser(user));
        }
    }, [user, dashboard]);

    if (!isInitialized) {
        return (
            <Backdrop className={classes.backdrop} open={showSpinner}/>
        )
    } else {
        return (
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.root}>
                <Paper elevation={3} className={classes.paper}>
                    <Box p={2}>

                        <Box mt={0}>
                            <Typography variant="h5" align="center">
                                Choose a team
                            </Typography>
                        </Box>

                        <Box mt={3} className={classes.list}>
                            {
                                clients.length > 0 &&
                                <List>
                                    {clients.map(client => (
                                        <ItemClient key={client.client_id}
                                                    client={client}
                                                    onClick={() => onClickClient(client)}/>
                                    ))}
                                </List>
                            }
                            {
                                clients.length === 0 &&
                                <Box display={"flex"} alignItems={"center"} mx={2} height={"100%"}>
                                    <Typography variant={"body1"} align={"center"}>
                                        You do not have access to any company.
                                    </Typography>
                                </Box>
                            }
                        </Box>

                        {
                            isBackEnabled &&
                            <Box mt={4} mb={1} ml={1} mr={1} display={"flex"} alignItems={"center"}>
                                <Box position={"absolute"}>
                                    <Typography variant="body2" align="left">
                                        <Link onClick={onClickBack} href={"#"}>
                                            <ArrowBack/>
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography variant="body2" align="center">
                                        <Link color={"primary"} onClick={onClickLogout} href={"#"}>
                                            <FormattedMessage id="Login.Logout" defaultMessage="Logout"/>
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        {
                            !isBackEnabled &&
                            <Box mt={4}>
                                <Link color="primary" onClick={onClickLogout} href={"#"}>
                                    <Typography variant="body2" align="center">
                                        <FormattedMessage id="Login.Logout" defaultMessage="Logout"/>
                                    </Typography>
                                </Link>
                            </Box>
                        }
                    </Box>
                    {showSpinner && <LinearProgress/>}
                    <Backdrop className={classes.backdrop} open={showSpinner}/>
                </Paper>
            </Grid>
        );
    }
}

const ItemClient = ({client, onClick}) => {
    const [logo, setLogo] =  useState(null);
    const [isClientsInitialized, setIsClientsInitialized] = useState(null);
    useEffect(() => {
        if(!isClientsInitialized){
            setIsClientsInitialized(true);
            Api.getClientConfigByClientHash(client.hash)(
                response =>{
                    const {data} = response;
                    setLogo(data.client?.brand?.logo?.square)
                },
                error => {

                }
            )
        }
    }, [client, isClientsInitialized]);
    return (
        <ListItem button alignItems="flex-start" onClick={onClick}>
            <ListItemAvatar>
                <Avatar alt={client.name}
                        src={(logo ?? "https://sitecdn.tvpage.com/static/images/default_brand_icon.png")}/>
            </ListItemAvatar>
            <ListItemText primary={client.name} secondary={client.client_id}/>
        </ListItem>
    )
}

export default ClientSelector;
