import urlHelper from "./urlHelper";
import {isEmpty} from "./utils";

const ROLES = Object.freeze({
    ROLE_BRAND_ADMIN: 1,
    ROLE_CREATOR: 2,
    ROLE_SUPER_ADMIN: 3,
    ROLE_MARKETPLACE: 4,
});

const DASHBOARDS = Object.freeze({
    DASHBOARD_CREATOR: "creator",
    DASHBOARD_BRAND: "brand",
    DASHBOARD_ADMIN: "admin",
    DASHBOARD_MARKETPLACE: "marketplace",
});

const ROLE_STATUS = Object.freeze({
    PENDING: 1,
    APPROVED: 2,
    SUSPENDED: 3,
    TEMPORARILY_SUSPENDED: 4,
    REVIEW: 5,
    REJECTED: 6,
});

const roleHelper = {
    getClientUserRoles: (user, allRoles = false) => {
        if (user && user.hasOwnProperty("roles") && allRoles) {
            return user.roles;
        } else if (user && user.hasOwnProperty("roles")) {
            return user.roles.filter(r => r.ambassador_type !== 3);
        } else {
            return [];
        }
    },
    getActiveClientUserRoles: (user) => {
        if (user && user.hasOwnProperty("roles")) {
            return user.roles.filter(r => (r.ambassador_type !== 3 && r.status === ROLE_STATUS.APPROVED) || r.role.role_id === 3);
        } else {
            return [];
        }
    },
    getClientUserRolesForUserWithNonClientRoleIds: (user, roleIds, allRoles = false) => {
        const clientUserRoles = roleHelper.getClientUserRoles(user, allRoles);
        if (clientUserRoles.length > 0 && !isEmpty(roleIds)) {
            return clientUserRoles.filter(r => isEmpty(r.client) && roleIds.includes(r.role.role_id));
        } else {
            return [];
        }
    },
    getClientUserRolesWithRoleIdsAndClientHash: (user, roleIds, clientHash, allRoles = false) => {
        const clientUserRoles = roleHelper.getClientUserRoles(user, allRoles);
        if (clientUserRoles.length > 0 && !isEmpty(roleIds)) {
            return clientUserRoles.filter(r => (
                (!isEmpty(clientHash) && r.client && r.client.hash === clientHash && roleIds.includes(r.role.role_id)) ||
                (isEmpty(clientHash) && isEmpty(r.client) && roleIds.includes(r.role.role_id))
            ));
        } else {
            return [];
        }
    },
    getClientUserRolesByClientHash: (user, clientHash) => {
        const clientUserRoles = roleHelper.getClientUserRoles(user);
        if (clientUserRoles.length > 0 && clientHash) {
            return clientUserRoles.filter(r => r.client && r.client.hash === clientHash);
        } else {
            return [];
        }
    },
    getActiveClientsFromUser: (user) => {
        const clientUserRoles = roleHelper.getActiveClientUserRoles(user);
        if (clientUserRoles.length > 0) {
            const clients = clientUserRoles.filter(r => r.client).map(r => r.client);
            return [...new Set(clients.map(c => c.client_id))].map(id => clients.find(c => c.client_id === id));
        } else {
            return [];
        }
    },
    getActiveRolesFromUser: (user) => {
        const clientUserRoles = roleHelper.getActiveClientUserRoles(user);
        if (clientUserRoles.length > 0) {
            const roles = clientUserRoles.map(r => r.role);
            return [...new Set(roles.map(r => r.role_id))].map(id => roles.find(r => r.role_id === id));
        } else {
            return [];
        }
    },
    isValidRedirectUrlForUser: (user, redirectUrl) => {
        const clientHash = urlHelper.getClientHash(redirectUrl);
        const roleIds = roleHelper.getRoleIdsFromUrl(redirectUrl);
        if (clientHash && roleIds.length > 0) {
            return roleHelper.getClientUserRolesWithRoleIdsAndClientHash(user, roleIds, clientHash, true).length > 0;
        } else if (roleIds.length > 0) {
            return roleHelper.getClientUserRolesForUserWithNonClientRoleIds(user, roleIds, false).length > 0;
        } else {
            return false;
        }
    },
    getDashboardsFromRoleId: (roleId) => {
        if (roleId === ROLES.ROLE_BRAND_ADMIN) {
            return [DASHBOARDS.DASHBOARD_BRAND];
        } else if (roleId === ROLES.ROLE_CREATOR) {
            return [DASHBOARDS.DASHBOARD_CREATOR];
        } else if (roleId === ROLES.ROLE_SUPER_ADMIN) {
            return [DASHBOARDS.DASHBOARD_BRAND, DASHBOARDS.DASHBOARD_ADMIN];
        } else if (roleId === ROLES.ROLE_MARKETPLACE) {
            return [DASHBOARDS.DASHBOARD_MARKETPLACE];
        } else {
            return [];
        }
    },
    getRoleIdsFromDashboard: (dashboard) => {
        if (dashboard === DASHBOARDS.DASHBOARD_BRAND) {
            return [ROLES.ROLE_BRAND_ADMIN, ROLES.ROLE_SUPER_ADMIN];
        } else if (dashboard === DASHBOARDS.DASHBOARD_CREATOR) {
            return [ROLES.ROLE_CREATOR];
        } else if (dashboard === DASHBOARDS.DASHBOARD_ADMIN) {
            return [ROLES.ROLE_SUPER_ADMIN];
        } else if (dashboard === DASHBOARDS.DASHBOARD_MARKETPLACE) {
            return [ROLES.ROLE_MARKETPLACE];
        } else {
            return [];
        }
    },
    getActiveClientUserRoleFromUserAndDashboard: (user, dashboard) => {
        const dashboardRoles = roleHelper.getRoleIdsFromDashboard(dashboard);
        const clients = roleHelper.getActiveClientUserRoles(user).filter(cur => dashboardRoles.includes(cur.role.role_id) && cur.client).map(cur => cur.client);
        return [...new Set(clients.map(c => c.client_id))].map(id => clients.find(c => c.client_id === id));
    },
    getRoleIdsFromUrl: (urlString) => {
        if (urlHelper.isLegacyRedirect(urlString)) {
            return [ROLES.ROLE_CREATOR];
        } else if (!urlHelper.isValidUrl(urlString)) {
            return [];
        }
        const url = new URL(urlString);
        if (!urlHelper.isAllowedHost(url.host)) {
            return [];
        }
        const pathParts = url.pathname.split('/');
        const hostParts = url.hostname.split('.');
        if ('ambassadors' === pathParts[1] || hostParts[0] === "creator") {
            return [ROLES.ROLE_CREATOR];
        } else if (hostParts[0] === "creators") {
            return [ROLES.ROLE_CREATOR, ROLES.ROLE_MARKETPLACE];
        } else if (["brand", "campaigns"].includes(hostParts[0])) {
            return [ROLES.ROLE_BRAND_ADMIN];
        } else if ("admin" === hostParts[0]) {
            return [ROLES.ROLE_SUPER_ADMIN];
        } else {
            return [];
        }
    },
    getClientUserRolesForUrl: (user, urlString) => {
        const clientHash = urlHelper.getClientHash(urlString);
        const roleIds = roleHelper.getRoleIdsFromUrl(urlString);
        return roleHelper.getClientUserRolesWithRoleIdsAndClientHash(user, roleIds, clientHash);
    },
    getLastInactiveRole: (user) => {
        const notActiveRoles = (user?.roles?.filter(r => r.status !== ROLE_STATUS.APPROVED && [ROLES.ROLE_CREATOR, ROLES.ROLE_MARKETPLACE, ROLES.ROLE_BRAND_ADMIN].includes(r.role.role_id) && r.ambassador_type !==3) ?? []);
        if (notActiveRoles.length > 0) {
            return notActiveRoles.sort((a, b) => {
                if (isEmpty(a.last_login_timestamp)) {
                    return +1;
                } else {
                    return (new Date(a.last_login_timestamp)).getTime() - (new Date(b.last_login_timestamp)).getTime();
                }
            })[0];
        } else {
            return null;
        }
    },
    getInactiveAccountMessage: (user) => {
        const lastInactiveRole = roleHelper.getLastInactiveRole(user);
        if ([ROLE_STATUS.PENDING, ROLE_STATUS.REVIEW].includes(lastInactiveRole?.status)) {
            return {message: `Your account is pending or under review.`, type: "info"};
        } else if (lastInactiveRole?.status === ROLE_STATUS.REJECTED) {
            return {message: "Your application has been rejected, please contact your program administrator for assistance.", type: "error"};
        } else {
            return {message: `Your account has been suspended, please contact your program administrator for assistance.`, type: "error"};
        }
    },
    getActiveBrandAdminRoles: (user) => roleHelper.getActiveClientUserRoles(user).filter(r => r?.role?.role_id === ROLES.ROLE_BRAND_ADMIN),
    getMarketplaceRole: (user) => user?.roles?.find(r => r?.role?.role_id === ROLES.ROLE_MARKETPLACE),
}

export {ROLES, DASHBOARDS, ROLE_STATUS};
export default roleHelper;
