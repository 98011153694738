import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMe, loginSelector} from "../slices/login";
import LoadingApp from "../components/LoadingApp";
import Login from "./Login";
import roleHelper, {DASHBOARDS, ROLE_STATUS, ROLES} from "../helpers/roleHelper";
import routes from "../helpers/routes";
import DashboardSelector from "./DashboardSelector";
import qs from "qs";
import useLocalStorage from "react-use-localstorage";
import Api from "../helpers/api";
import urlHelper from "../helpers/urlHelper";
import {app as appUtils, isEmpty} from "../helpers/utils";
import ClientSelector from "./ClientSelector";
import {withRouter} from "react-router-dom";
import ClaimFlow from "./ClaimFlow";
import useNotification from "../helpers/useNotification";
import api from "../helpers/api";

const STEP_LOADING = "loading";
const STEP_LOGIN = "login";
const STEP_CLAIM = "claim";
const STEP_CLIENT_SELECTION = "client_selection";
const STEP_DASHBOARD_SELECTION = "dashboard_selection";

const getDashboardUrlForClientUserRole = (clientUserRole) => {
    if (ROLES.ROLE_MARKETPLACE === clientUserRole.role.role_id) {
        return routes.getDefaultCreatorsMarketplaceDashboardUrl();
    } else if (ROLES.ROLE_CREATOR === clientUserRole.role.role_id) {
        return routes.geCreatorsAppUrl(`/${clientUserRole.client.hash}/campaigns`);
    } else if (ROLES.ROLE_BRAND_ADMIN === clientUserRole.role.role_id) {
        return routes.getDefaultBrandDashboardUrl(clientUserRole.client.hash);
    } else if (ROLES.ROLE_SUPER_ADMIN === clientUserRole.role.role_id) {
        return routes.getDefaultAdminDashboardUrl();
    } else {
        return null;
    }
}

const UserRedirectFlow = ({location, history}) => {
    const dispatch = useDispatch();
    const notification = useNotification();
    const {isAuthenticated, user, campaignSession} = useSelector(loginSelector);
    const [currentStep, setCurrentStep] = useState(STEP_LOADING);
    const [continueUrl, setContinueUrl] = useLocalStorage('continueUrl', null);
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [isRedirecting, setIsRedirecting] = useState(false);

    const sendDashboardHit = useCallback((user, clientUserRole) => {
        return Api.dashboardHit(user.user_id)(
            () => {
            }, () => {
            }
        )({client_id: clientUserRole?.client?.client_id, role_id: clientUserRole.role.role_id});
    }, []);

    const doRedirectToUrl = useCallback((user, redirectUrl) => {
        const clientUserRoles = roleHelper.getClientUserRolesForUrl(user, redirectUrl);
        const clientUserRoleForHit = clientUserRoles.find(r => !isEmpty(r.client));

        if (clientUserRoleForHit) {
            sendDashboardHit(user, clientUserRoleForHit).then(() => {
                setIsRedirecting(true);
                setContinueUrl(null);
                window.location.href = redirectUrl;
            });
        } else {
            setIsRedirecting(true);
            setContinueUrl(null);
            window.location.href = redirectUrl;
        }
    }, [sendDashboardHit, setContinueUrl]);

    const doRedirectToDashboard = useCallback((user, clientUserRole) => {
        sendDashboardHit(user, clientUserRole)
            .then(() => window.location.href = getDashboardUrlForClientUserRole(clientUserRole));
    }, [sendDashboardHit]);

    const getValidRedirectUrlForUser = useCallback((user, redirectUrl) => {
        const isValidRedirect = !isEmpty(redirectUrl) && roleHelper.isValidRedirectUrlForUser(user, redirectUrl);
        if (isValidRedirect && urlHelper.isLegacyRedirect(redirectUrl)) {
            const clientHash = urlHelper.getClientHash(redirectUrl);
            return routes.geCreatorsAppUrl(`/${clientHash}/campaigns`);
        } else if (isValidRedirect) {
            return redirectUrl;
        } else {
            return null;
        }
    }, []);

    const createCampaignAndRedirect = useCallback((clientId, userId, baseUrl)=>{
        api.campaigns.createBooking(campaignSession)(
            response=>{
                window.location.href = `${baseUrl}/influencer/campaigns/booking/${response.data.id}/wizard`
            },
            error =>{
                if(baseUrl){
                    window.location.href = baseUrl;
                }
            }
        )({clientId, userId});
    },[campaignSession])

    useEffect(() => {
        if (isAuthenticated && continueUrl === null && !isRedirecting) {
            const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
            if (!isEmpty(queryParams.continue)) {
                setContinueUrl(queryParams.continue);
            } else {
                setContinueUrl("");
            }
        }
    }, [setContinueUrl, continueUrl, user, isRedirecting, isAuthenticated, location.search]);

    useEffect(() => {
        const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (!isEmpty(queryParams.continue) && continueUrl !== queryParams.continue && !isRedirecting) {
            setContinueUrl(queryParams.continue);
        }
    }, [setContinueUrl, continueUrl, isRedirecting, location.search]);

    useEffect(() => {
        if (isAuthenticated === null) {
            dispatch(getMe());
        } else if (isAuthenticated === false) {
            setCurrentStep(STEP_LOGIN);
        }
    }, [isAuthenticated, dispatch]);

    useEffect(() => {
        if (isAuthenticated && continueUrl !== null && !isRedirecting) {
            const clientUserRoles = roleHelper.getActiveClientUserRoles(user);
            const clients = roleHelper.getActiveClientsFromUser(user);
            const roles = roleHelper.getActiveRolesFromUser(user);
            const marketplaceRole = roleHelper.getMarketplaceRole(user);
            const validRedirectUrl = getValidRedirectUrlForUser(user, continueUrl);
            if (user?.claimable) {
                setCurrentStep(STEP_CLAIM);
                setIsRedirecting(false);
            } else if (!isEmpty(validRedirectUrl)) {
                setIsRedirecting(true);
                doRedirectToUrl(user, validRedirectUrl);
            } else if (clientUserRoles.length === 1 && getDashboardUrlForClientUserRole(clientUserRoles[0])) {
                const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
                if(clientUserRoles[0].role.role_id === ROLES.ROLE_BRAND_ADMIN && queryParams.bookReference){
                    createCampaignAndRedirect(clientUserRoles[0].client.client_id, queryParams.bookReference, getDashboardUrlForClientUserRole(clientUserRoles[0]), null);
                }else{
                    setIsRedirecting(true);
                    doRedirectToDashboard(user, clientUserRoles[0]);
                }

            } else if (marketplaceRole && marketplaceRole.status === ROLE_STATUS.APPROVED && roles.map(r => r.role_id === 1).length === 0) {
                setIsRedirecting(true);
                doRedirectToDashboard(user, marketplaceRole);
            } else if (marketplaceRole && marketplaceRole.status !== ROLE_STATUS.APPROVED && roles.map(r => r.role_id === 1).length === 0) {
                setIsRedirecting(false);
                setCurrentStep(STEP_LOGIN);
                const inactiveAccountMessage = roleHelper.getInactiveAccountMessage(user);
                if (inactiveAccountMessage.type === "info") {
                    notification.info(inactiveAccountMessage.message);
                } else {
                    notification.error(inactiveAccountMessage.message);
                }
            } else if ((roles.length === 1 && clients.length > 1) || !isEmpty(selectedDashboard)) {
                setCurrentStep(STEP_CLIENT_SELECTION);
            } else if (isAuthenticated && clientUserRoles.length === 0) {
                appUtils.clearStorage();
                history.push(routes.getLogoutUrl());
            } else if (isAuthenticated) {
                setCurrentStep(STEP_DASHBOARD_SELECTION);
            }
        }
    }, [isAuthenticated, continueUrl, user, selectedDashboard, isRedirecting, doRedirectToUrl, doRedirectToDashboard, getValidRedirectUrlForUser, notification, history, location, createCampaignAndRedirect, campaignSession]);

    const onClickLogout = () => {
        appUtils.clearStorage();
        history.push(routes.getLogoutUrl());
    }

    const onClickClient = (client) => {
        const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
        if (selectedDashboard) {
            if(queryParams.bookReference && selectedDashboard === DASHBOARDS.DASHBOARD_BRAND){
                createCampaignAndRedirect(client.client_id, queryParams.bookReference, routes.getDashboardUrlByDashboardName(selectedDashboard, client.hash));
            }else{
                window.location.href = routes.getDashboardUrlByDashboardName(selectedDashboard, client.hash);
            }
        } else {
            const clientUserRoles = roleHelper.getClientUserRolesByClientHash(user, client.hash);
            if(clientUserRoles[0].role.role_id === ROLES.ROLE_BRAND_ADMIN && queryParams.bookReference){
                createCampaignAndRedirect(clientUserRoles[0].client.client_id, queryParams.bookReference, getDashboardUrlForClientUserRole(clientUserRoles[0]));
            }else{
                window.location.href = getDashboardUrlForClientUserRole(clientUserRoles[0]);
            }

        }
    }

    const onClickDashboard = (dashboard) => {
        if (dashboard === DASHBOARDS.DASHBOARD_ADMIN) {
            window.location.href = routes.getAdminDashboardUrl();
        } else if (dashboard === DASHBOARDS.DASHBOARD_MARKETPLACE) {
            window.location.href = routes.getDefaultCreatorsMarketplaceDashboardUrl();
        } else {
            const clientsForDashboard = roleHelper.getActiveClientUserRoleFromUserAndDashboard(user, dashboard);
            if (clientsForDashboard.length === 1) {
                const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
                if(queryParams.bookReference && dashboard === DASHBOARDS.DASHBOARD_BRAND){
                    createCampaignAndRedirect(clientsForDashboard[0].client_id, queryParams.bookReference, routes.getDashboardUrlByDashboardName(dashboard, clientsForDashboard[0].hash));
                }else{
                    window.location.href = routes.getDashboardUrlByDashboardName(dashboard, clientsForDashboard[0].hash);
                }
            } else {
                setSelectedDashboard(dashboard);
                setCurrentStep(STEP_CLIENT_SELECTION);
            }
        }
    }

    const onClickBackToDashboardSelection = () => {
        setSelectedDashboard(null);
        setCurrentStep(STEP_DASHBOARD_SELECTION);
    }

    if (currentStep === STEP_LOADING) {
        return <LoadingApp/>;
    } else if (currentStep === STEP_LOGIN) {
        return <Login/>;
    } else if (currentStep === STEP_DASHBOARD_SELECTION) {
        return <DashboardSelector onClickDashboard={onClickDashboard} onClickLogout={onClickLogout}/>;
    } else if (currentStep === STEP_CLAIM) {
        return <ClaimFlow/>
    } else if (currentStep === STEP_CLIENT_SELECTION) {
        return <ClientSelector onClickClient={onClickClient} onClickLogout={onClickLogout} dashboard={selectedDashboard}
                               isBackEnabled={selectedDashboard !== null}
                               onClickBack={onClickBackToDashboardSelection}/>;
    }
}

export default withRouter(UserRedirectFlow);
